import React, { useState, useEffect } from "react";
import "./Address.css";
import addressempty from "../../Assests/4860769.jpg";
import { Route, useNavigate, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { IoIosHome } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineEditLocationAlt, MdOutlineWork } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import Api from '../../Global/Api'
// import { Circle, GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";

const Address = (route) => {
  const [addresses, setAddresses] = useState([]);

  const [id, setId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [latitude, setLatitude] = useState(11.024739617227985);
  const [longitude, setLongitude] = useState(77.01762053355134);
  const [type, setType] = useState("");
  const [line, setLine] = useState("");
  const [landmark, setLandmark] = useState("");
  const [deliveryType, setDeliveryType] = useState("DELIVERY TO HOME");
  const [loginAddress, setLoginAddress] = useState("");
  const [name, setName] = useState("");
  const [addNum, setAddNum] = useState("");
  const [aid, setAid] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  console.log(loginAddress);
  console.log('object', addresses)
  const [map, setMap] = React.useState(null);
  const handleClose = () => setShowModal(false);
  const navigate = useNavigate();
  const bid = localStorage.getItem("branchid");
  const callApi = new Api();
  console.log(latitude, longitude)
  // const position = {lat:latlang.lat, lng:latlang.lang };
  const location = useLocation();
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(location.state ? location.state.Id : null);
  console.log(selectedAddressIndex);
  const handleMapClick = (event) => {
    const { latLng } = event;
    setLatitude(latLng.lat());
    setLongitude(latLng.lng());
  };




  const getLocations = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLatitude(latitude);
          setLongitude(longitude);
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const getData = async () => {
    try {
      const user_id = localStorage.getItem('user_id');
      callApi.getAddress({ uid: user_id }).then((data) => {
        console.log('datadata', data)
        setAddresses(data.address);
      })
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEditClick = (id, latlng) => {
    setId(id);
    console.log("EDIT", latlng)
    getLocations();
    const [lat, lang] = latlng.split(',');
    console.log(lat, lang)
    setLatitude(lat);
    setLongitude(lang);
    addresses.map((item, index) => {
      // console.log(item);
      if (id === item.id) {
        console.log(item)

        setLine(item.line1);
        setDeliveryType(item.type);
        setLandmark(item.landmark);
        setName(item.name);
        setAddNum(item.aditional_number);
        setAid(item.id);
        handleShow('EDIT');
      }
    });
  };

  const handleDeleteClick = async (id, bid) => {
    console.log('id', id)
    console.log('idid', bid)
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this Address?"
    );
    if (shouldDelete) {
      try {
        callApi.deleteAddress({
          del_id: id,
          bid: bid
        }).then((data) => {
          console.log('data', data)
          setAddresses((prevAddresses) =>
            prevAddresses.filter((address) => address.id !== id)
          );
        })

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      console.log("Deletion cancelled by user");
    }
  };

  const handleShow = (ty) => {
    console.log(' set', ty)
    if (ty === 'ADD') {
      getLocations();
      setId("");
      setLandmark("");
      setDeliveryType("");
      setLine("");
      setType('ADD');
      setShowModal(true);
      setAddNum("");
      setName("");
      handleMap();
    } else {
      setType('EDIT');
      setShowModal(true);
    }

  };
  //


  const handleClick = async (index) => {
    setSelectedAddressIndex(index)
    const selectedAddress = addresses[index];
    await localStorage.setItem("addressId", selectedAddress.id);
    await localStorage.setItem("latlang", selectedAddress.latlng);
    navigate("/Mainpage", {
      state: {
        cameFromAddressPage: true,
        data: selectedAddress,
      },
    });
  };

  const handleMap = async () => {
    console.log("map called")
    const Api =
      `https://maps.googleapis.com/maps/api/geocode/json?address=${latitude},${longitude}&key=`;
    fetch(
      Api
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson)
        const addr = JSON.stringify(
          responseJson.results[2].formatted_address
        ).replace(/"/g, "");
        setLine(addr);
        localStorage.setItem("lat", latitude);
        localStorage.setItem("long", longitude);
      })

      .catch((error) => {
        console.error("Error fetching address:", error);
      });
    //                ///// map  key dont use without permission
  }
  //Map Show Functions Start

  const handleDeliveryType = (delType) => {
    if (delType === "DELIVERY TO HOME") {
      setDeliveryType("DELIVERY TO HOME");
    } else if (delType === "DELIVERY TO WORK") {
      setDeliveryType("DELIVERY TO WORK");
    } else {
      setDeliveryType("DELIVERY TO OTHER");
    }
  }
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    // Allow letters and spaces, disallow other characters
    const onlyLettersAndSpaces = inputValue.replace(/[^A-Za-z\s]/g, '');
    // Update the state with the filtered value
    setName(onlyLettersAndSpaces);
  };



  const handleSaveAddress = () => {
    if (!name || !line || !landmark || !addNum || !deliveryType) {
      alert("Please enter all details");
      return; // Exit the function if any field is empty
    }

    if (addNum.length !== 10 || !/^\d{10}$/.test(addNum)) {
      alert("Please enter a valid 10-digit mobile number.");
      return; // Exit the function if the mobile number is not exactly 10 digits
    }
  
    setIsButtonDisabled(true);
    try {

      if (type === 'ADD') {
        const user_id = localStorage.getItem('user_id');
        const bid = localStorage.getItem('branchid');
        const latlang = `${latitude},${longitude}`
        callApi.addAddress({
          uid: user_id,
          type: deliveryType,
          name: name,
          latlng: latlang,
          line1: line,
          landmark: landmark,
          addiphne: addNum,
          bid: bid,
        }).then((res) => {
          if (res.data.success == "1") {
            setShowModal(false);
            setId("");
            setLandmark("");
            setDeliveryType("");
            setLine("");
            setType('ADD');
            setAddNum("");
            setName("");
            setIsButtonDisabled(false);
            window.location.reload();


          }
        })
      } else {
        console.log("edit")
        const user_id = localStorage.getItem('user_id');
        const bid = localStorage.getItem('branchid');
        const latlang = `${latitude},${longitude}`;
        callApi.editAddress({
          aid: aid,
          uid: user_id,
          type: deliveryType,
          name: name,
          latlng: latlang,
          line1: line,
          landmark: landmark,
          addiphne: addNum,
          bid: bid,
        }).then((res) => {
          if (res.data.success == "1") {
            setShowModal(false);
            setId("");
            setLandmark("");
            setDeliveryType("");
            setLine("");
            setType('ADD');
            setAddNum("");
            setName("");
            setAid("");
            setIsButtonDisabled(true);
            window.location.reload();
          }
        })
      }
    } catch {

    }

  }

  // const center = {
  //   lat: latitude,
  //   lng: longitude,
  // }


  // const onLoad = React.useCallback(function callback(map) {

  //   const bounds = new window.google.maps.LatLngBounds(center);
  //   map.fitBounds(bounds);

  //   setMap(map)
  // }, [])

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null)
  // }, [])



  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container">
      {addresses.length === 0 ? (
        <div className="d-flex flex-column align-items-center">
          <img
            src={addressempty}
            alt="orderempty"
            className="img-addtocart img-fluid"
          />
          <span className="span-txt">
            You don't have an address to deliver.
          </span>
          <button className="btn-order" onClick={() => handleShow('ADD')}>
            Add Address
          </button>
        </div>
      ) : (
        <div className=" fixed-heading">
          <div>
            <h1 className="d-flex justify-content-center bold heading">
              Manage Address
            </h1>
          </div>
          <div className="right-align">
            <button className="btn-address" onClick={() => handleShow('ADD')}>
              Add Address
            </button>
          </div>
          {addresses.map((address, index) => (
            bid === address.bid && (
              <div key={index} className={`card add_card ${selectedAddressIndex === address.id ? 'select' : ''}`}>
                <h4 style={{ fontFamily: "heading" }}>{address.type}</h4>
                <div className="add_card-values">
                  <h6>{address.name ? address.name : ""}</h6>
                  <h6>{address.landmark ? address.landmark : ""}</h6>
                  <h6>{address.line1}</h6>
                </div>
                <div className="addd_head">
                  <button
                    className="btn-del m-2"
                    onClick={() => handleDeleteClick(address.id, address.bid)}
                  >
                    DELETE
                  </button>
                  <button
                    className="btn-edt m-2"
                    onClick={() => handleEditClick(address.id, address.latlng)}
                  >
                    EDIT
                  </button>

                  <button
                    className={`btn-edt_69 m-2 `}
                    onClick={() => handleClick(index)}
                  >
                    SELECT
                  </button>
                </div>
              </div>
            )
          ))}
        </div>
      )}
      <Modal
        show={showModal}
        onHide={handleClose}
        dialogClassName="modal-dialog"
        className="center-aligned-modal"
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="">Save Delivery Address</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mp_body">
          <div className="container">
            <div className="row">
              <div className="map-container m-3">
                <APIProvider apiKey={""}>
                  <Map
                    style={{ width: "90%", height: "400px" }}
                    defaultCenter={{ lat: latitude, lng: longitude }}
                    defaultZoom={15}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                    viewState={{ latitude, longitude, zoom: 16 }}
                  // onCenterChanged={handleMap}
                  >

                    <Marker position={{ lat: latitude, lng: longitude }}
                      draggable={true}
                      onDragEnd={(event) => {
                        setLatitude(event.latLng.lat);
                        setLongitude(event.latLng.lng);
                        handleMap()
                      }}
                    />
                  </Map>
                </APIProvider>
              </div>
              {type === 'EDIT' ? (
                <div>
                  <div className="address_input">
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="Name"
                      maxLength={20}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="Address"
                      value={line}
                      onChange={(e) => setLine(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="Landmark"
                      value={landmark}
                      onChange={(e) => setLandmark(e.target.value)}
                    />
                    <input
                      type="tel"
                      className="form-control mb-3"
                      placeholder="Additional Number"
                      value={addNum}
                      maxLength={10}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) { 
                          setAddNum(e.target.value);
                        }
                      }}
                      onKeyDown={(e) => {
                        // Allow only numbers to be typed
                        const isNumberKey = e.key >= '0' && e.key <= '9';
                        const isBackspaceKey = e.key === 'Backspace';
                        const isArrowKey = e.key === 'ArrowLeft' || e.key === 'ArrowRight';
                        const isDeleteKey = e.key === 'Delete';
                        const isTabKey = e.key === 'Tab';
                        const isNumPadKey = e.keyCode >= 96 && e.keyCode <= 105; // Num Lock number keys

                        // Prevent input if the key pressed is not a number, Backspace, arrow key, Delete, Num Lock number keys, or Tab
                        if (
                          !isNumberKey &&
                          !isBackspaceKey &&
                          !isArrowKey &&
                          !isDeleteKey &&
                          !isTabKey &&
                          !isNumPadKey
                        ) {
                          e.preventDefault();
                        }
                        if (
                          (e.shiftKey && (e.keyCode >= 48 && e.keyCode <= 57)) || // Prevent typing special characters with Shift + number keys
                          ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '+', '=', '-', '_'].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <div className="d-flex justify-content-between mt-2">
                      <button
                        className={`home-work-other ${deliveryType === "DELIVERY TO HOME"
                          ? "orange-sel"
                          : ""
                          }`}
                        onClick={() => handleDeliveryType("DELIVERY TO HOME")}
                      >
                        <IoIosHome />
                        Home
                      </button>
                      <button
                        className={`home-work-other ${deliveryType === "DELIVERY TO WORK"
                          ? "orange-sel"
                          : ""
                          }`}
                        onClick={() => handleDeliveryType("DELIVERY TO WORK")}
                      >
                        <MdOutlineWork />
                        Work
                      </button>
                      <button
                        className={`home-work-other ${deliveryType === "DELIVERY TO OTHER"
                          ? "orange-sel"
                          : ""
                          }`}
                        onClick={() => handleDeliveryType("DELIVERY TO OTHER")}
                      >
                        <FaLocationDot />
                        Other
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="address_input">
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="Name"
                      maxLength={20}
                      value={name}
                      onChange={handleNameChange}
                    />
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="Address"
                      value={line}
                      onChange={(e) => setLine(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="Landmark"
                      value={landmark}
                      onChange={(e) => setLandmark(e.target.value)}
                    />
                    <input
                      type="tel"
                      className="form-control mb-3"
                      placeholder="Additional Mobile Number"
                      value={addNum}
                      maxLength={10}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) { 
                          setAddNum(e.target.value);
                        }
                      }}
                      onKeyDown={(e) => {
                        // Allow only numbers to be typed
                        const isNumberKey = e.key >= '0' && e.key <= '9';
                        const isBackspaceKey = e.key === 'Backspace';
                        const isArrowKey = e.key === 'ArrowLeft' || e.key === 'ArrowRight';
                        const isDeleteKey = e.key === 'Delete';
                        const isTabKey = e.key === 'Tab';
                        const isNumPadKey = e.keyCode >= 96 && e.keyCode <= 105; // Num Lock number keys

                        // Prevent input if the key pressed is not a number, Backspace, arrow key, Delete, Num Lock number keys, or Tab
                        if (
                          !isNumberKey &&
                          !isBackspaceKey &&
                          !isArrowKey &&
                          !isDeleteKey &&
                          !isTabKey &&
                          !isNumPadKey
                        ) {
                          e.preventDefault();
                        }
                        if (
                          (e.shiftKey && (e.keyCode >= 48 && e.keyCode <= 57)) || // Prevent typing special characters with Shift + number keys
                          ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '+', '=', '-', '_'].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />

                    <div className="d-flex justify-content-between mt-2">
                      <button
                        className={`home-work-other ${deliveryType === "DELIVERY TO HOME"
                          ? "orange-sel"
                          : ""
                          }`}
                        onClick={() => handleDeliveryType("DELIVERY TO HOME")}
                      >
                        <IoIosHome />
                        Home
                      </button>
                      <button
                        className={`home-work-other ${deliveryType === "DELIVERY TO WORK"
                          ? "orange-sel"
                          : ""
                          }`}
                        onClick={() => handleDeliveryType("DELIVERY TO WORK")}
                      >
                        <MdOutlineWork />
                        Work
                      </button>
                      <button
                        className={`home-work-other ${deliveryType === "DELIVERY TO OTHER"
                          ? "orange-sel"
                          : ""
                          }`}
                        onClick={() => handleDeliveryType("DELIVERY TO OTHER")}
                      >
                        <FaLocationDot />
                        Other
                      </button>
                    </div>
                  </div>
                </div>
              )}

            </div>
            <div className="row">
              <div className="col mt-3">
                <button className="save_address_1 w-100" disabled={isButtonDisabled} onClick={handleSaveAddress}  >
                  SAVE ADDRESS & PROCEED
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Address;
