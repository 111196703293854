import React from 'react'
import './Refund.css'
import Footer from '../Footer/Footer'

function Refund() {
  return (
    <div className='ref'>
    <div className='refund_1'>
        <h2>Cancellations and Refunds</h2>
    </div>
      <div className='refund'>
        <b>Cancellation i.</b>  As a general rule you shall not be entitled to cancel your order once you have received confirmation of the same. If you cancel your order after it has been confirmed,Q2H MEATS shall have a right to charge you cancellation fee of a minimum INR 75 upto the order value, with a right to either not to refund the order value or recover from your subsequent order, the complete/ deficit cancellation fee, as applicable, to compensate our branch and delivery partners. Q2H MEATS shall also have right to charge you cancellation fee for the orders cancelled by Q2H MEATS for the reasons specified under clause 1(iii) of this cancellation and refunds policy. In case of cancellations for the reasons attributable to Q2H MEATS or its restaurant and delivery partners,Q2H MEATS shall not charge you any cancellation fee.<br/>
      </div>
        <div className='refund'>
            ii. However, in the unlikely event of an item on your order being unavailable, we will contact you on the phone number provided to us at the time of placing the order and inform you of such unavailability. In such an event you will be entitled to cancel the entire order and shall be entitled to a refund in accordance with our refund policy.<br/>
        </div>
        <div className='refund'>
        iii. We reserve the sole right to cancel your order in the following circumstance: a. in the event of the designated address falls outside the delivery zone offered by us; b. failure to contact you by phone or email at the time of confirming the order booking; c. failure to deliver your order due to lack of information, direction or authorization from you at the time of delivery; or d. unavailability of all the items ordered by you at the time of booking the order; or e. unavailability of all the items ordered by you at the time of booking the order; or 2. Refunds i. You shall be entitled to a refund only if you pre-pay for your order at the time of placing your order on the Platform and only in the event of any of the following circumstances: a. us cancelling your order due to (A) your delivery location following outside our designated delivery zones; (B) failure to contact you by phone or email at the time of confirming the order booking; or (C) failure to contact you by phone or email at the time of confirming the order booking; or b. you cancelling the order at the time of confirmation due to unavailability of the items you ordered for at the time of booking. ii. Our decision on refunds shall be at our sole discretion and shall be final and binding. iii. All refund amounts shall be credited to your account within 7 business days in accordance with the terms that may be stipulated by the bank which has issued the credit / debit card. In case of payment at the time of delivery, you will not be required to pay for: i. wrong order being delivered;
        </div>
        
         </div>
        
     
      
      
    
  )
}

export default Refund
