import React, { useEffect, useState, useRef, useCallback } from "react";
import "./Mainhome.css";
import q2h from "../../Assests/q2h.png";
import chicken from "../../Assests/chicken-top.jpg";
import mutton from "../../Assests/mutton.png";
import chickenmutton from "../../Assests/chicken-mutton.jpg";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import eggs from "../../Assests/eggs.png";
import fish from "../../Assests/fish.webp";
import Quail from "../../Assests/Quail.jpg";
import Quailegg from "../../Assests/Quail_Eggs.jpg";
import branch from "../../Assests/branch (1).png";
import { useDispatch, useSelector } from "react-redux";
import { addItems, addToCart3, removeFromCart } from "../Redux/cartActions";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Carousel } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import cartimg from "../../Assests/cart-web.png";
import Swal from 'sweetalert2';
import Lottie from 'react-lottie';
import {
  PiHouse,
  PiShoppingCartSimpleLight,
  PiUser,
  PiUserBold,
  PiUserCircle,
  PiUserCircleBold,
} from "react-icons/pi";
import veg from "../../Assests/veg.png";
import ban_1 from "../../Assests/ban-1.jpg";
import ban_2 from "../../Assests/ban-2.jpg";
import ban_3 from "../../Assests/ban-3.jpg";
import ban_4 from "../../Assests/ban-4.jpg";
import nonveg from "../../Assests/non_veg.png";
import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { FaCodeBranch, FaSearch } from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import Api from "../../Global/Api";
import { Token } from "../../Global/Firebase";
import cashfree from "../../Assests/cashfree.png";
import razorpay from "../../Assests/razorpay.png";
import { MdReportGmailerrorred } from "react-icons/md";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import DatePicker from "react-date-picker";
import TimePicker from "react-time-picker";
import DateTimePicker from "react-datetime-picker";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
// import { FORMATDATE as formatDate } from '../Helper/helper.js';
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
// import LoadingSpinner from "../Loading/LoadingSpinner";
import { useLocation } from "react-router-dom";
import { FaShop } from "react-icons/fa6";
import cashfreeImage from "../../Assests/cashfree.png";
import razorPayImage from "../../Assests/razorpay.png";
import cod from "../../Assests/cod.jpg";
import { load } from "@cashfreepayments/cashfree-js";

import LoadingSpinner from "../Loading/LoadingSpinner";
const banner_IMAGE = `https://q2h.in/admin/images/banner/`;
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
function Mainhome() {
  const location = useLocation();
  const [emailError, setEmailError] = useState(null);
  const { state } = location || {};
  const { data } = state || {};

  const { cameFromAddressPage } = state || {};
  const loginame = localStorage.getItem("name");

  const [menu, setMenu] = useState([]);
  const [bannarImage, setBannarImage] = useState([]);
  const [addondata, setAddondata] = useState([]);
  // console.log({addondata:addondata})
  const [modalOpen, setModalOpen] = useState(false);
  const [alart, setAlart] = useState(false);
  const [isCartShaking, setCartShaking] = useState(false);
  const [todaySpecial, setTodaySpecial] = useState(null);
  const handleCloose = () => {
    setShow(false);         // Close the alert by updating the state
    window.location.reload(); // Reload the page
  };
  const [showLottie, setShowLottie] = useState(false);
  const [addonModal, setAddonModal] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [selectedMeat, setSelectedMeat] = useState(null);
  const [cart, setCart] = useState([]);
  const [showmodalBtn, setShowmodalBtn] = useState(false);
  const [addnew, setAddnew] = useState(false);
  const [showCart, setShowcart] = useState(false);
  const [branchId, setBranchId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loadingItems, setLoadingItems] = useState([]);
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [billTotal, setBillTotal] = useState(0);
  const [couponApplied, setCouponApplied] = useState({});
  const [promoText, setPromoText] = useState("");
  const [billDiscount, setBillDiscount] = useState(0);
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [deliveryType, setDeliveryType] = useState("home");
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [modalDelivery, setModalDelivery] = useState(false);
  const dispatch = useDispatch();
  const [showLogin, setShowLogin] = useState(true);
  const [showSignup, setShowSignup] = useState(true);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [otp, setOtp] = useState(false);
  // const [promoText, setPromoText] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [mob, setMob] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [otpNew, setOtpNew] = useState(["", "", "", ""]);
  const [newOtp, setNewOtp] = useState("");
  const [error, setError] = useState(null);
  const [otpView, setOtpView] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [otpGenerated, setOtpGenerated] = useState(false);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(59);
  const [isVerifyButton, setVerifyButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [editaddonModal, setEditAddonModal] = useState(false);
  const [isPlusButtonDisabled, setIsPlusButtonDisabled] = useState(false);
  const [isMinusButtonDisabled, setIsMinusButtonDisabled] = useState(false);
  const scrollToItemRef = useRef(null);
  const CallApi = new Api();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [customName, setCustomName] = useState("");
  const [chooseItem, setChooseItem] = useState("");
  const [branchStatus, setBranchStatus] = useState({});
  const searchResultsRef = useRef(null);
  const [isAddButtonDisabled, setAddButtonDisabled] = useState(false);
  const [instruction, setInstruction] = useState(null);
  const [payId, setPayId] = useState("Cash On Delivery");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [initialMenu, setInitialMenu] = useState([]);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [time, setTime] = useState(
    getFormattedDate(deliveryType === "self" ? 15 : 60)
  );
  const [date, setDate] = useState(new Date());
  const [muttonsel, setMuttonsel] = useState(false);
  const [chickensel, setChickensel] = useState(false);
  const [allitemsel, setAllitemsel] = useState(true);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(-1);
  const [totalCusPrice, setTotalCusPrice] = useState(0);
  const handleClickOutside = (e) => {
    if (
      searchResultsRef.current &&
      !searchResultsRef.current.contains(e.target)
    ) {
      setSearchResults([]);
      setSelectedCategoryIndex(-1);
      setSelectedItemIndex(-1);
    }
  };
  const [futureOrder, setFutureOrder] = useState(0);
  const log = localStorage.getItem("isLoggedIn");

  const setCartToLocalStorage = (cart) =>
    localStorage.setItem("cart", JSON.stringify(cart));
  const itemsInCart = JSON.parse(localStorage.getItem("cart")) || [];
  // console.log("mkl", itemsInCart)

  const menu_item_image = "https://q2h.in/admin/images/menu_item_icon/";

  const OverAllImage = async () => {
    try {
      const response1 = await fetch(
        `https://q2h.in/admin/api/banner.php?branch=${branchId}`
      );

      if (!response1.ok) {
        throw new Error(`Failed to fetch. Status: ${response1.status}`);
      }

      const data1 = await response1.json();
      setBannarImage(data1.banner);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (timeLeft === 0) {
      // setInputDisabled(false);
      // setEnabled(false);
      setButtonDisabled(true);
    }
  }, [timeLeft]);
  const fetchDataOnce = async () => {
    // console.log("jjkk", branchId);

    // setMenu([])
    // const storedBrachid = localStorage.getItem("branchid");
    const prevBranchid = localStorage.getItem("prevBranchid") || "";
    const dataFetched = localStorage.getItem("dataFetched");
    // const orderSuccess= localStorage.getItem("ordersuccess");
    if (dataFetched === "false") {
      localStorage.removeItem("menuLocal");
      localStorage.removeItem("cart");
      localStorage.removeItem("dataFetched");
      try {
        // Fetch data from the API
        const res = await fetch(
          `https://q2h.in/admin/api/categorywithitem_test.php?branch=${branchId}`
        );
        const apiData = await res.json();
        console.log(res);
        // Set to localStorage
        localStorage.setItem("menuLocal", JSON.stringify(apiData));
        localStorage.setItem("dataFetched", "true"); // Set flag in localStorage
        // localStorage.setItem("ordersuccess","false")
        // Set the values in state
        setMenu(apiData);
      } catch (error) {
        console.log("Error in fetching data: ", error);
      }
    } else {
      // Data already fetched, retrieve from localStorage
      const storedData = localStorage.getItem("menuLocal");
      setMenu(storedData ? JSON.parse(storedData) : []);
    }
    const storedCartData = localStorage.getItem("cart");
    setCart(storedCartData ? JSON.parse(storedCartData) : []);
  };

  const totalCount =
    menu.menu_category &&
    menu.menu_category.reduce((acc, category) => {
      const count = category.data.reduce((categoryCount, item) => {
        return item.qty > 0 ? categoryCount + Number(item.qty) : categoryCount;
      }, 0);
      return acc + count;
    }, 0);
  const fetchAddons = async ({
    categoryId,
    itemId,
    selectedItem,
    itemIndex,
  }) => {
    //fetch data from url
    console.log("categoryIdqq", categoryId);
    const url = `https://q2h.in/admin/api/ingredientsnew.php?category=${categoryId}&menu_id=${itemId}`;
    console.log(url);
    const response = await fetch(url);
    const data = await response.json();
    console.log({ data: data });
    setCustomName(selectedItem.menu_name);
    //check the url contains data or not
    if (
      data &&
      data.variant &&
      Array.isArray(data.variant) &&
      data.variant.some(
        (variant) =>
          variant.data && Array.isArray(variant.data) && variant.data.length > 0
      )
    ) {
      setAddondata(data);
      setAddonModal(true);

      setSelectedMeat({ categoryId, itemId, selectedItem });
    } else {
      incrementItemQty({ categoryId, itemId });
    }
  };
  const fetchAddonsChoose = async ({
    categoryId,
    itemId,
    chooseItem,
    selectedItem,
  }) => {
    //fetch data from url
    const url = `https://q2h.in/admin/api/ingredientsnew.php?category=${categoryId}&menu_id=${itemId}`;
    const response = await fetch(url);
    const data = await response.json();
    setCustomName(chooseItem.menu_name);
    console.log(url);
    // console.log(chooseItem);
    //check the url contains data or not
    if (
      data &&
      data.variant &&
      Array.isArray(data.variant) &&
      data.variant.some(
        (variant) =>
          variant.data && Array.isArray(variant.data) && variant.data.length > 0
      )
    ) {
      setAddondata(data);
      setAddonModal(true);

      setSelectedMeat({ categoryId, itemId, selectedItem });
    } else {
      incrementItemQty({ categoryId, itemId });
    }
  };
  const fetchInitialMenu = async () => {
    const initialMenuDataLocal =
      (await JSON.parse(localStorage.getItem("menuLocal"))) || [];
    setInitialMenu(initialMenuDataLocal);
    setMenu(initialMenuDataLocal);
  };

  const handleBranchStatus = async () => {
    const bid = localStorage.getItem("branchid");
    const Api = `https://q2h.in/admin/api/status_all.php?bid=${bid}`;
    const res = await fetch(Api);
    const data = await res.json();
    let tempNewObj = {};
    {
      data.status.map((status, statusIndex) => {
        if (status.status_name == "tax") {
          tempNewObj[status.status_name] = parseFloat(status.tax);
        } else if (status.status_name == "home_delivery(meat)") {
          tempNewObj["home_delivery_meat"] = parseFloat(status.status);
        } else if (status.status_name == "min_amount") {
          tempNewObj[status.status_name] = parseFloat(status.min_amount);
        } else if (status.status_name == "del_max_amount") {
          tempNewObj[status.status_name] = parseFloat(status.max_amount);
        } else {
          tempNewObj[status.status_name] = parseFloat(status.status);
        }
      });
    }
    if (tempNewObj.home_delivery != 1) {
      setDeliveryType("self");
    }
    setBranchStatus(tempNewObj);
  };
  const handlePaymentMethod = ({ gateway, paymentType }) => {
    if (paymentType === "card") {
      setPayId("payconnecting");
      handleOrder(gateway, paymentType);
    } else {
      setPayId("Cash On Delivery");
      handleOrder("cash", "cash");
    }
    // setShowcart(false);
    // localStorage.setItem("cart", JSON.stringify([]));
    // localStorage.setItem("dataFetched", "false");
    // fetchDataOnce();
    // setShowPaymentOptions(false);
    // setModalDelivery(true);
  };
  const handleCancelOtp = () => {
    setInputDisabled(false);
    setEnabled(false);
    setButtonDisabled(false);

    setOtp(false);
    setOtpGenerated(false);
  };

  const handleOrder = async (gateway, paymentType) => {
    setShowcart(false);
    setShowLottie(true);
    let newCredit = { Order: [] };
    let ProceedOrder = true;
    itemsInCart.map((normalItem) => {
      if (normalItem.status && normalItem.status == 0) {
        ProceedOrder = false;
      }
      let tempObj = {};
      tempObj.ItemId = normalItem.id;
      tempObj.ItemName = normalItem.menu_name;
      tempObj.ItemQty = normalItem.qty;
      tempObj.ItemAmt = normalItem.dprice
        ? normalItem.dprice
        : normalItem.price;
      tempObj.ItemTotalPrice =
        normalItem.qty * normalItem.dprice
          ? normalItem.dprice
          : normalItem.price;
      tempObj.ingredientsdetail = normalItem.selectedIngredients;
      tempObj.size = normalItem.size;
      tempObj.variant =
        normalItem.selectedVariants?.map((variant) => variant.id) || [];
      // tempObj.variantName =
      //   normalItem.selectedVariants?.map(
      //     (variant) => variant.selectedVariant
      //   ) || [];
      // tempObj.variantName =
      //   normalItem.selectedVariants?.map(
      //     (variant) => `"${variant.selectedVariant}"`
      //   ).join(", ") || "";
      tempObj.variantName =
        normalItem.selectedVariants
          ?.map((variant) => variant.selectedVariant)
          .join(", ") || "";
      tempObj.ItemDiscount = 0;
      tempObj.flatappid = null;
      tempObj.buyyapppid = "0";
      tempObj.version = "web";
      newCredit.Order.push(tempObj);
    });

    const food_desc = encodeURIComponent(JSON.stringify(newCredit));
    console.log("variant", JSON.stringify(newCredit.Order));
    const userId = await localStorage.getItem("user_id");
    const mailId = await localStorage.getItem("mail_id");
    const Token = await localStorage.getItem("token");
    const BranchId = await localStorage.getItem("branchid");
    const discount = await localStorage.getItem("coupon_dis");
    const Aid =
      deliveryType == "home" ? await localStorage.getItem("addressId") : "0";
    const aidFoodOrder =
      deliveryType == "home" ? await localStorage.getItem("addressId") : "00";
    const LatLangFood =
      deliveryType == "home" ? await localStorage.getItem("latlang") : "0";
    const pad = (n) => (n < 10 ? '0' + n : n);
    const hours = time.getHours();
    const formattedHours = hours % 12 || 12; // Convert 24-hour format to 12-hour format
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const selftime = `${pad(time.getDate())}-${pad(time.getMonth() + 1)}-${time.getFullYear()} ${pad(formattedHours)}:${pad(time.getMinutes())} ${ampm}`;

    CallApi.placeOrder({
      userId: userId,
      name: "user",
      mailId: mailId,
      addressFoorOrder: Aid,
      paymentType: paymentType,
      instructions: instruction,
      city: "cbe",
      food_desc: food_desc,
      total_price: billTotal.toFixed(2),
      latlongFoodOrder: LatLangFood,
      fireBaseToken: Token,
      payId: payId,
      a_idFoorOrder: aidFoodOrder,
      deliveryCharges: deliveryCharges,
      billDiscount: discount ? discount : 0,
      selftime: selftime,
      tipAmount: "0",
      branchId: BranchId,
      future: futureOrder,
      app_Version: "web",
    }).then( async (res) => {
      console.log({ resres: res });
      if (ProceedOrder === true) {
        const discount = await localStorage.removeItem("coupon_dis");
        if (res.success === "Success" || res.success === 1) {
        
          if (paymentType === "card") {
            handleOnlinePayment(gateway, res.order_id, billTotal.toFixed(2));
          } else {
            cashonDelivery();
          }
        } else {
          alert(res.order_details);
          setShowLottie(false);
        }
      }
    });
  };

  const handleOnlinePayment = async (paymentMethod, orderID, billTotal) => {
    console.log(paymentMethod, orderID, billTotal);
    if (paymentMethod === "razorpay") {
      try {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        script.onload = () => {
          CallApi.getRazorPayToken({ billTotal: billTotal }).then(
            (tokenDataRespons) => {
              console.log(tokenDataRespons);
              const options = {
                description: "Payment",
                image: "https://q2h.in/admin/images/logo/logo.png",
                order_id: tokenDataRespons.oid,
                currency: "INR",
                key: "rzp_live_qA3V0vXmWvzFLg",
                amount: billTotal,
                name: "",
                prefill: {
                  email: localStorage.getItem("mail_id"),
                  contact: localStorage.getItem("mobile_no"),
                },
                modal: {
                  escape: false,
                },
                theme: {
                  color: "#ff931e",
                },
                handler: async function (response) {
                  console.log(response);
                  CallApi.razorPayWebCheckout({
                    raz_payid: response.razorpay_payment_id,
                    raz_oid: response.razorpay_order_id,
                    raz_sign: response.razorpay_signature,
                    oid: response.razorpay_order_id,
                  }).then((res) => {
                    if (res.msg == "Success") {
                      CallApi.updatePaymentId({
                        oid: orderID,
                        referenceId: res.razorpay_paymentId,
                        status: 1,
                      }).then((raz) => {
                        console.log(raz.data.success);
                        if (raz.data.success == 1) {
                          cashonDelivery();
                        }else {
                          setShowLottie(false);
                        }
                      });
                    } else {
                      CallApi.updatePaymentId({
                        orderId: tokenDataRespons.oid,
                        referenceId: "failedpayment",
                        status: 0,
                      });
                      setShowLottie(false);
                      setShowcart(false);
                    }
                  });
                },
                modal: {
                  confirm_close: false,
                  ondismiss: async (reason) => {
                    console.log("reason", reason);
                    // const {
                    //   reason: paymentReason, field, step, code,
                    // } = reason && reason.error ? reason.error : {};
                    if (reason === undefined) {
                      setShowLottie(false);
                      console.log("cancelled");
                    } else if (reason === "timeout") {
                      setShowLottie(false);
                      console.log("timedout");
                    } else {
                      setShowLottie(false);
                      console.log("failed");
                      // handlePayment('failed', {
                      //   paymentReason, field, step, code,
                      // });
                    }
                  },
                },
                retry: {
                  enabled: false,
                },
                timeout: 300,
              };
              const rzp = new window.Razorpay(options);

              rzp.open();
            }
          );
        };
        document.body.appendChild(script);
        return () => {
          document.body.removeChild(script);
        };
      } catch (error) {
        console.error("Error loading Razorpay script:", error);
      }
    } else if (paymentMethod === "cashfree") {
      let cashfree;
      var initializeSDK = async function () {
        cashfree = await load({
          mode: "sandbox",
        });
      };
      initializeSDK();
      try {
        const Api = `https://q2h.in/admin/api/cashfree_generate_token_id.php?amt=${billTotal}&orderId=${orderID}&paymethod=${paymentMethod}`;
        console.log(Api);
        const response = await fetch(Api);
        const result = await response.json();
        let checkoutOptions = {
          paymentSessionId: result.payment_session_id,
          redirectTarget: "_self",
        };
        cashfree.checkout(checkoutOptions);
        setShowPaymentOptions(false);
        // cashonDelivery();
      } catch (e) {
        console.log(e.message);
      }
    }
  };

  // const handleSearch = async (e) => {
  //   try {
  //     // Wait for fetchInitialMenu to complete
  //     await fetchInitialMenu();

  //     const term = e.target.value.toLowerCase();
  //     console.log('Search Term:', term);

  //     setSearchTerm(term);

  //     if (term.trim() === '') {  // Use trim() to handle white spaces
  //       const initialMenuData = JSON.parse(localStorage.getItem("menuLocal")) || [];
  //       setMenu(initialMenuData);
  //       setSearchResults([]);
  //       console.log('Search Results Cleared:', initialMenuData);
  //     } else {
  //       // Log the fetched initialMenu data
  //       console.log('Fetched Initial Menu Data:', initialMenu);

  //       // Filter the data based on the search term
  //       const filteredData = initialMenu.menu_category && initialMenu.menu_category.reduce((acc, category) => {
  //         const categoryData = category.data.filter((menuItem) =>
  //           menuItem.menu_name.toLowerCase().includes(term)
  //         );

  //         if (categoryData.length > 0) {
  //           acc.push({ ...category, data: categoryData });
  //         }

  //         return acc;
  //       }, []);

  //       // Set the menu state with the filtered data
  //       setMenu({ menu_category: filteredData });
  //       console.log('Filtered Menu:', filteredData);

  //       // Update the search results if needed
  //       setSearchResults(filteredData);
  //       console.log('Search Results Updated:', filteredData);
  //     }
  //   } catch (error) {
  //     console.error('Error in handleSearch:', error);
  //   }
  // };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    setSearchTerm(term);

    if (term === "") {
      const initialMenuData =
        JSON.parse(localStorage.getItem("menuLocal")) || [];
      setMenu(initialMenuData);
      setSearchResults([]);
    } else {
      const filteredData =
        initialMenu.menu_category &&
        initialMenu.menu_category.reduce((acc, category) => {
          const categoryData = category.data.filter((menuItem) =>
            menuItem.menu_name.toLowerCase().includes(term)
          );

          if (categoryData.length > 0) {
            acc.push({ ...category, data: categoryData });
            // console.log(acc)
          }

          return acc;
        }, []);

      // setMenu({ menu_category: filteredData });
      setSearchResults(filteredData);
    }
  };
  const memoizedFetchInitialMenu = useCallback(fetchInitialMenu, []);

  // useEffect(() => {
  //   const handleStorageChange = (event) => {
  //     if (event.key === 'menuLocal') {
  //       fetchInitialMenu();
  //     }
  //   };

  //   // Add an event listener to listen for changes in 'menuLocal'
  //   window.addEventListener('storage', handleStorageChange);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('storage', handleStorageChange);
  //   };
  // }, [fetchInitialMenu]);
  const handleImgClick = () => {
    // setInitialMenu([]); // You might want to set it based on the data for the new branch
    // localStorage.removeItem("menuLocal");

    // setMenu([]); // Reset menu to an empty state
    // setSearchTerm('')

    navigate("/BranchPage");
  };
  // const handleSearch = (e) => {
  //   const term = e.target.value.toLowerCase();
  //   setSearchTerm(term);

  //   if (term === "") {
  //     const storedData = localStorage.getItem("menuLocal");
  //     setMenu(storedData ? JSON.parse(storedData) : []);
  //     setSearchResults([]);
  //   } else {
  //     const newMenu = menu.menu_category
  //       ? menu.menu_category.map((category) => {
  //           const filteredData = category.data.filter((menuItem) =>
  //             menuItem.menu_name.toLowerCase().includes(term)
  //           );
  //           return { ...category, data: filteredData };
  //         }, [])
  //       : [];

  //     setMenu((prevMenu) => ({
  //       ...prevMenu,
  //       menu_category: newMenu,
  //     }));
  //   }
  // };

  //increment the quantity of items

  const handleIncrement = (categoryId, itemId, selectedItem, itemIndex) => {
    if (!isAddButtonDisabled) {
      setAddButtonDisabled(true);

      fetchAddons({ categoryId, itemId, selectedItem, itemIndex });
    }
  };
  // const handleIncrement = (categoryId, itemId, selectedItem, itemIndex) => {

  //     fetchAddons({ categoryId, itemId, selectedItem, itemIndex });

  // };

  //getting addon data for particular item

  const handleLgOtp = () => {
    const regex = /^\d{10}$/;
    const isValidMobileNumber = regex.test(mobileNumber);

    if (!isValidMobileNumber) {
      setErrorMessage("Please enter a valid 10-digit mobile number");
      return;
    }

    try {
      // handleOtpCounter();
      setVerifyButton(true);

      const hash = "";
      const token = localStorage.getItem("token");
      const tk = encodeURIComponent(hash);
      const tk1 = encodeURIComponent(token);
      const os = "web";
      CallApi.getLogin({
        mobile_no: mobileNumber,
        type: os,
        token: tk1,
        hash: tk,
      }).then((res) => {
        if (res.data.success === "1") {
          localStorage.setItem("mail_id", res.data.mail_id);
          localStorage.setItem("name", res.data.name);
          setOtpGenerated(true);
          setOtp(true);
          setOtpView(true);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'This Mobile Number Does Not Exit!',
            text: ' Sign Up to Continue!',
          });
          setOtpGenerated(false);
          setOtp(false);
          setInputDisabled(false);
          setButtonDisabled(false);
          setEnabled(false);
        }
      });
    } catch (err) {
      setError(err);
    }
    setEnabled(true);

    setInputDisabled(true);
    setButtonDisabled(true);
    setTimeLeft(59);
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(inputValue)) {
      setName(inputValue);
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setMobileNumber(inputValue);

  };

  const handleNOBlur = () => {
    const pattern = /^\d{10}$/; // Adjusted pattern to match exactly 10 digits
    if (!pattern.test(mobileNumber)) {
      setError("Please enter a valid 10-digit mobile number");
    } else {                                                                                
      setError("");
    }
  };

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    const pattern = /^[a-zA-Z0-9._%+-]+@gmail\.com$/; // Regex pattern for gmail.com emails
    if (pattern.test(inputValue)) {
      setEmailError(""); // Clear any previous error if the entered email is valid
    }
  };

  const handleEmailBlur = () => {
    const pattern = /^[a-zA-Z0-9._%+-]+@gmail\.com$/; // Regex pattern for gmail.com emails
    if (!pattern.test(email)) {
      setEmailError("Please enter a valid Gmail address");
    } else {
      setEmailError(""); // Clear any previous error
    }
  };

  // const handleSignup = () => {
  //   setInputDisabled(true);
  //   setButtonDisabled(true);
  //   setEnabled(true);
  //   if (name && mobileNumber && email) {
  //     if (!error && !emailError) {
  //       try {
  //         // handleOtpCounter();


  //         const hash = "";
  //         const token = localStorage.getItem("token");
  //         const tk = encodeURIComponent(hash);
  //         const tk1 = encodeURIComponent(token);
  //         const os = "web";
  //         CallApi.getSignUp({
  //           maild: email,
  //           mobile_no: mobileNumber,
  //           type: os,
  //           token: tk1,
  //           hash: tk,
  //         }).then((res) => {
  //           if (res.data.success === "1") {
  //             localStorage.setItem("isLoggedIn", true);

  //             setTimeLeft(59);


  //             setOtpView(true);
  //             setShowSignup(false);
  //             setVerifyButton(true);
  //             setOtpGenerated(true);
  //             setOtp(true);
  //           } else {
  //             alert(res.data.error)
  //             handleCancelOtp()
  //           }
  //         });
  //       } catch (err) {
  //         setError(err);
  //       }
  //     } else {

  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Invalid error',
  //         text: 'Please clear the error before proceeding',
  //       });
  //     }
  //   } else {

  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Missing Fields',
  //       text: 'Please enter all the Details',
  //     });
  //     setInputDisabled(false);
  //     setButtonDisabled(false);
  //     setEnabled(false);
  //   }
  // };
  const handleSignup = () => {
   
    if (name && mobileNumber && email) {
      if (!error && !emailError) {
        try {
          // handleOtpCounter();

          setShowSignup(false);
          setVerifyButton(true);
          setButtonDisabled(true);
          setEnabled(true);
          const hash = "";
          const token = localStorage.getItem("token");
          const tk = encodeURIComponent(hash);
          const tk1 = encodeURIComponent(token);
          const os = "web";
          CallApi.getSignUp({
            maild: email,
            mobile_no: mobileNumber,
            type: os,
            token: tk1,
            hash: tk,
          }).then((res) => {
            if (res.data.success === "1") {
              localStorage.setItem("isLoggedIn", true);
              setOtpView(true);
              setInputDisabled(true);
              setTimeLeft(59);
             
              setOtpGenerated(true);
          setOtp(true);
            }else {
              alert(res.data.error);
              setOtpView(false);
              handleCancelOtp();
            };
          });
        } catch (err) {
          setError(err);
        }
      } else {
        alert("Please clear the error before proceeding.");

      }
    } else {
      alert("Please enter all the Details");
      setInputDisabled(false)
      setButtonDisabled(false)
      setEnabled(false)

    }

  };

  const Todayspecial = async () => {
    const response1 = await fetch(
      `https://q2h.in/admin/api/wholelist_test.php?branch=${branchId}`
    );
    const res = await response1.json();
    setTodaySpecial(res.wholelist);
  };
  const otpInputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const handleOTPInputChange = (e, index) => {
    const value = e.target.value;
    setOtpNew((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      if (/^[0-9]*$/.test(value) && value !== "" && index < 3) {
        otpInputRefs[index + 1].current.focus();
      }
      const allInputsFilled = newOtp.every((input) => /^[0-9]*$/.test(input));
      setVerifyButton(allInputsFilled);
      const otpString = newOtp.join("");
      setNewOtp(otpString);
      return newOtp;
    });

    if (/^[0-9]*$/.test(value) && value !== "" && index < 3) {
      otpInputRefs[index + 1].current.focus();
    }

    if (otpInputRefs.every((ref) => ref.current.value)) {
      setVerifyButton(true);
    } else {
      setVerifyButton(false);
    }
  };
  const handleOtpVerify = () => {
    if (newOtp) {
      try {
        let dataToSend = { mobileNumber: mobileNumber };
        let formBody = [];
        for (let key in dataToSend) {
          let encodedKey = encodeURIComponent(key);
          let encodedValue = encodeURIComponent(dataToSend[key]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        CallApi.getOtp({
          mobile_no: mobileNumber,
          otp: newOtp,
        }).then((res) => {
          if (res.data.success === "1") {
            localStorage.setItem("user_id", res.data.userid);
            localStorage.setItem("mobile_no", mobileNumber);
            localStorage.setItem("isLoggedIn", true);

            setShow(false);
          } else if (res.data.success === "0") {
            Swal.fire({
              icon: 'error',
              title: 'Invalid OTP',
              text: 'Please enter a valid OTP',
            });
          }
        });
      } catch (err) {
        setError(err);
      }
    } else {

      Swal.fire({
        icon: 'error',
        title: 'Missing FIELDS',
        text: 'Please enter otp',
      });
    }
  };

  const handleLoginClick = () => {
    setShowSignupForm(false); // Hide signup form
    setIsLogin(true);
  };

  const OverAllCat = async () => {
    const response = await fetch(
      `https://q2h.in/admin/api/categorywithitem_test.php?branch=${branchId}`
    );
    const data = await response.json();
    dispatch(addToCart3(data.menu_category));
  };

  function getFormattedDate(min = 0) {
    const formattedDate = new Date();
    formattedDate.setTime(new Date().getTime() + min * 60 * 1000);
    return formattedDate;
  }

  const handleDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    if (selectedDate != new Date()) {
      setDate(selectedDate);
      setFutureOrder(1);
    } else {
      setDate(selectedDate);
      setFutureOrder(0);
    }
  };
  const handleFutureOrder = () => {
    const currentDate = handleDateChange();
    console.log(currentDate);
  };

  const handleTimeChange = (event) => {
    const selectedTime = new Date();
    const [hours, minutes] = event.target.value.split(":");
    selectedTime.setHours(parseInt(hours, 10));
    selectedTime.setMinutes(parseInt(minutes, 10));
    setTime(selectedTime);
  };

  const handleResendOTP = () => {
    setResendEnabled(false);
    setOtpGenerated(true);
    setOtp(true);
    setTimeLeft(59);
    const hash = ""; // Not sure what this is intended for
    const token = localStorage.getItem("token");
    const tk = encodeURIComponent(hash);
    const tk1 = encodeURIComponent(token);
    const os = "web";
    CallApi.getLogin({
      mobile_no: mobileNumber,
      type: os,
      token: tk1,
      hash: tk,
    }).then((res) => {
      if (res.data.success === "1") {
        console.log(res.data.mail_id);
        localStorage.setItem("mail_id", res.data.mail_id);
        setEmail(res.data.mail_id);
        setOtpView(true);
      }
    });
    setInputDisabled(true);
  };
  const handleSignin = () => {
    setIsLogin(false);
    setShowSignupForm(true);
    setShowLogin(false);
    setOtp(false);
    setShowSignup(false);
    setInputDisabled(false);
    setButtonDisabled(false);
    setEnabled(false);
    setName("");
    setMobileNumber("");
    setEmail("");
  };

  const handleMobileNumberChange = (e) => {
    
    if (e.target.value.length <= 10) { 
      const input = e.target.value;
      if (/^\d{0,10}$/.test(input)) {
        setMobileNumber(input);
        setErrorMessage("");
      }
    }
 
    
  };
  const handleLogin = () => {
    setIsLogin(true);
    setShowSignupForm(false);
    setShowLogin(false);
    setShowSignup(false);
  };

  // useEffect(() => {
  //   const getValue = localStorage.getItem("branchid");
  //   setBranchId(getValue);
  // }, []);

  // const handleSearchChange = (event) => {
  //   const query = event.target.value.toLowerCase();
  //   setSearchQuery(query);
  // };

  // const fetchDataOnce = async () => {
  //   // console.log("jjkk", branchId);

  //   // setMenu([])
  //   // const storedBrachid = localStorage.getItem("branchid");

  //   const dataFetched = localStorage.getItem("dataFetched");
  //   if (dataFetched === "false") {
  //     localStorage.removeItem("menuLocal");
  //     localStorage.removeItem("cart");
  //     localStorage.removeItem("dataFetched");
  //     try {
  //       // Fetch data from the API
  //       const res = await fetch(
  //         `https://q2h.in/admin/api/categorywithitem_test.php?branch=${branchId}`
  //       );
  //       const apiData = await res.json();

  //       // Set to localStorage
  //       localStorage.setItem("menuLocal", JSON.stringify(apiData));
  //       localStorage.setItem("dataFetched", "true"); // Set flag in localStorage

  //       // Set the values in state
  //       setMenu(apiData);
  //     } catch (error) {
  //       console.log("Error in fetching data: ", error);
  //     }
  //   } else {
  //     // Data already fetched, retrieve from localStorage
  //     const storedData = localStorage.getItem("menuLocal");
  //     setMenu(storedData ? JSON.parse(storedData) : []);
  //   }
  //   const storedCartData = localStorage.getItem("cart");
  //   setCart(storedCartData ? JSON.parse(storedCartData) : []);
  // };

  // console.log(menu);

  // addon datas

  const handleAddon = () => {
    // Get addon values and set the values in a array
    const selectedIngredients = [];
    const selectedaddondata = [];
    const selectedArrayaddondata = [];

    // Handle selected ingredients
    if (addondata.ingre && Array.isArray(addondata.ingre)) {
      addondata.ingre.forEach((ingredient, index) => {
        const checkbox = document.getElementById(`ingredient${index}`);
        if (checkbox && checkbox.checked) {
          selectedIngredients.push(ingredient.name, ingredient.id);
        }
      });
    }

    // Handle selected addondata
    if (addondata.variant && Array.isArray(addondata.variant)) {
      addondata.variant.forEach((variant, index) => {
        const selectedRadio = document.querySelector(
          `input[name="variant${index}"]:checked`
        );
        if (selectedRadio) {
          const selectedRadioIndex = selectedRadio.id.split("_")[1];
          const selectedVariantName =
            variant.data[selectedRadioIndex].variant_name;
          const selectedPrice = variant.data[selectedRadioIndex].price;
          selectedaddondata.push({
            heading: variant.heading,
            id: variant.data[selectedRadioIndex].id,
            // selectedVariant: selectedRadio.nextSibling.textContent,
            selectedVariant: selectedVariantName,
            price: selectedPrice,
          });
        }
      });
    }

    // Handle selected array addondata
    if (
      addondata.arrayvariant &&
      Array.isArray(addondata.arrayvariant) &&
      addondata.arrayvariant.length > 0
    ) {
      addondata.arrayvariant.forEach((arrayVariant, index) => {
        const selectedRadio = document.getElementById(`arrayVariant${index}`);
        if (selectedRadio && selectedRadio.checked) {
          selectedArrayaddondata.push(arrayVariant.name, arrayVariant.id);
        }
      });
    }

    console.log("Selected Ingredients:", selectedIngredients);
    console.log("Selected addondata:", selectedaddondata);
    console.log("Selected Array addondata:", selectedArrayaddondata);

    const extractCategoryAndMenuId = (addondata) => {
      if (
        addondata.variant &&
        Array.isArray(addondata.variant) &&
        addondata.variant.length > 0 &&
        addondata.variant[0].data &&
        Array.isArray(addondata.variant[0].data) &&
        addondata.variant[0].data.length > 0
      ) {
        return addondata.variant[0].data[0];
      } else if (
        addondata.variant &&
        Array.isArray(addondata.variant) &&
        addondata.variant.length > 1 &&
        addondata.variant[1].data &&
        Array.isArray(addondata.variant[1].data) &&
        addondata.variant[1].data.length > 0
      ) {
        return addondata.variant[1].data[0];
      } else {
        return {};
      }
    };

    const { category, menu_id } = extractCategoryAndMenuId(addondata);
    console.log("bbb", category);
    console.log(menu_id);

    if (category && menu_id && addnew) {
      const cartData = JSON.parse(localStorage.getItem("cart")) || [];

      const existingItemIndex = cartData.findIndex(
        (item) =>
          item.category === category &&
          item.id === menu_id &&
          JSON.stringify(item.selectedIngredients) ===
          JSON.stringify(selectedIngredients) &&
          JSON.stringify(item.selectedVariants) ===
          JSON.stringify(selectedaddondata) &&
          JSON.stringify(item.selectedArrayVariants) ===
          JSON.stringify(selectedArrayaddondata)
      );

      if (existingItemIndex !== -1) {
        incrementItemQtysameAddon({
          categoryId: category,
          itemId: menu_id,
          selectedIngredients: selectedIngredients,
          selectedVariants: selectedaddondata,
          selectedArrayVariants: selectedArrayaddondata,
        });
      } else {
        incrementNewItemQty({
          categoryId: category,
          itemId: menu_id,
          selectedIngredients: selectedIngredients,
          selectedVariants: selectedaddondata,
          selectedArrayVariants: selectedArrayaddondata,
        });
      }
    } else {
      incrementItemQty({
        categoryId: category,
        itemId: menu_id,
        selectedIngredients: selectedIngredients,
        selectedVariants: selectedaddondata,
        selectedArrayVariants: selectedArrayaddondata,
      });
    }
    setAddonModal(false);
  };
  const backupLogin = () => {
    setIsLogin(true);
    setShowSignupForm(false);
    setShowLogin(false);
    setInputDisabled(false);
    setOtp(false);
    setButtonDisabled(false);
    setEnabled(false);
  };

  const handleCheckOut = async (categoryId, itemId, item) => {
    console.log("categoryIdcategoryId", categoryId);
    const url = `https://q2h.in/admin/api/ingredientsnew.php?category=${categoryId}&menu_id=${itemId}`;
    const response = await fetch(url);
    const data = await response.json();

    setChooseItem(item);

    if (
      data &&
      data.variant &&
      Array.isArray(data.variant) &&
      data.variant.some(
        (variant) =>
          variant.data && Array.isArray(variant.data) && variant.data.length > 0
      )
    ) {
      setShowmodalBtn(true);
      setSelectedMeat({ categoryId, itemId });
    } else {
      incrementItemQty({ categoryId, itemId });
      // }
    }

    // Store selected meal
  };

  const incrementItemQtysameAddon = ({
    categoryId,
    itemId,
    selectedIngredients,
    selectedVariants,
    selectedArrayVariants,
  }) => {
    const menuLocalItem = JSON.parse(localStorage.getItem("menuLocal"));
    const updatedMenu = menuLocalItem.menu_category.map((category) => {
      if (category.cat_id === categoryId) {
        const updatedData = category.data.map((item) =>
          item.id === itemId
            ? {
              ...item,
              qty: String(
                Number(item.qty) + (item.wholecat === "2" ? 0.5 : 1)
              ),
            }
            : item
        );
        return { ...category, data: updatedData };
      }
      return category;
    });
    localStorage.setItem(
      "menuLocal",
      JSON.stringify({ ...menuLocalItem, menu_category: updatedMenu })
    );

    const existingCartItem = cart.find(
      (cartItem) =>
        cartItem.id === itemId &&
        cartItem.category === categoryId &&
        JSON.stringify(cartItem.selectedIngredients) ===
        JSON.stringify(selectedIngredients) &&
        JSON.stringify(cartItem.selectedVariants) ===
        JSON.stringify(selectedVariants) &&
        JSON.stringify(cartItem.selectedArrayVariants) ===
        JSON.stringify(selectedArrayVariants)
    );

    const updatedCart = existingCartItem
      ? cart.map((cartItem) => {
        if (
          cartItem.id === itemId &&
          cartItem.category === categoryId &&
          JSON.stringify(cartItem.selectedIngredients) ===
          JSON.stringify(selectedIngredients) &&
          JSON.stringify(cartItem.selectedVariants) ===
          JSON.stringify(selectedVariants) &&
          JSON.stringify(cartItem.selectedArrayVariants) ===
          JSON.stringify(selectedArrayVariants)
        ) {
          let qtyIncrement = 1; // Default quantity increment

          if (cartItem.wholecat === "2") {
            qtyIncrement = 0.5;
          }

          return {
            ...cartItem,
            qty: String(Number(cartItem.qty) + qtyIncrement),
          };
        }
        return cartItem;
      })
      : [
        ...cart,
        {
          ...menuLocalItem.menu_category
            .find((meal) => meal.cat_id === categoryId)
            .data.find((item) => item.id === itemId),
          qty:
            menuLocalItem.menu_category
              .find((meal) => meal.cat_id === categoryId)
              .data.find((item) => item.id === itemId).wholecat === "2"
              ? "0.5"
              : "1",
          selectedIngredients,
          selectedVariants,
          selectedArrayVariants,
        },
      ];

    setCartToLocalStorage(updatedCart);
    setMenu({ menu_category: updatedMenu });
    setCart(updatedCart);
    setInitialMenu({ menu_category: updatedMenu });
  };

  const incrementItemQty = ({
    categoryId,
    itemId,
    selectedIngredients,
    selectedVariants,
    selectedArrayVariants,
  }) => {
    const menuLocalItem = JSON.parse(localStorage.getItem("menuLocal"));

    const updatedMenu = menuLocalItem.menu_category.map((category) => {
      if (category.cat_id === categoryId) {
        console.log("categoryId", category.cat_id);
        console.log("categoryId", categoryId);

        const updatedData = category.data.map((item) => {
          console.log(item.id);
          console.log(itemId);

          if (item.id === itemId) {
            let qtyIncrement = 1;
            // console.log('object',item.wholecat)
            if (item.wholecat === "2") {
              qtyIncrement = 0.5;
            }

            return { ...item, qty: String(Number(item.qty) + qtyIncrement) };
          }
          return item;
        });

        return { ...category, data: updatedData };
      }
      return category;
    });

    localStorage.setItem(
      "menuLocal",
      JSON.stringify({ ...menuLocalItem, menu_category: updatedMenu })
    );

    const existingCartItem = cart.find((cartItem) => cartItem.id === itemId);

    const updatedCart = existingCartItem
      ? cart.map((cartItem) =>
        cartItem.id === itemId
          ? {
            ...cartItem,
            qty: String(
              Number(cartItem.qty) + (cartItem.wholecat === "2" ? 0.5 : 1)
            ),
          }
          : cartItem
      )
      : [
        ...cart,
        {
          ...menuLocalItem.menu_category
            .find((meal) => meal.cat_id === categoryId)
            .data.find((item) => item.id === itemId),
          qty:
            menuLocalItem.menu_category
              .find((meal) => meal.cat_id === categoryId)
              .data.find((item) => item.id === itemId).wholecat === "2"
              ? "0.5"
              : "1",
          selectedIngredients,
          selectedVariants,
          selectedArrayVariants,
        },
      ];

    setCartToLocalStorage(updatedCart);
    setMenu({ menu_category: updatedMenu });
    setCart(updatedCart);
    setInitialMenu({ menu_category: updatedMenu });
    setAddButtonDisabled(false);
    setIsPlusButtonDisabled(false);
  };

  // const response = incrementItemQty({
  //   categoryId: 'yourCategoryId',
  //   itemId: 'yourItemId',
  //   selectedIngredients: 'yourSelectedIngredients',
  //   selectedVariants: 'yourSelectedVariants',
  //   selectedArrayVariants: 'yourSelectedArrayVariants',
  // });

  // console.log(response);
  const handleDecrement = (categoryId, itemId) => {
    const menuLocalItem = JSON.parse(localStorage.getItem("menuLocal"));
    const lastIndex = cart
      .slice()
      .reverse()
      .findIndex((cartItem) => cartItem.id === itemId);

    if (lastIndex !== -1) {
      const originalIndex = cart.length - 1 - lastIndex;

      const lastAddedItem = cart[originalIndex];

      let qtyDecrement = 1;

      if (lastAddedItem.wholecat === "2") {
        qtyDecrement = 0.5;
      }

      const updatedCart = cart.map((cartItem, index) =>
        index === originalIndex
          ? {
            ...cartItem,
            qty: String(Number(cartItem.qty) - qtyDecrement),
            selectedIngredients:
              cartItem.selectedIngredients && Number(cartItem.qty) === 1
                ? []
                : cartItem.selectedIngredients,
            selectedVariants:
              cartItem.selectedVariants && Number(cartItem.qty) === 1
                ? []
                : cartItem.selectedVariants,
            selectedArrayVariants:
              cartItem.selectedArrayVariants && Number(cartItem.qty) === 1
                ? []
                : cartItem.selectedArrayVariants,
          }
          : cartItem
      );

      if (lastAddedItem.qty === "1" || lastAddedItem.qty === "0.5") {
        const updatedCartWithoutZeroQuantity = updatedCart.filter(
          (cartItem) => cartItem.qty > "0"
        );

        setCartToLocalStorage(updatedCartWithoutZeroQuantity);
        setCart(updatedCartWithoutZeroQuantity);
      } else {
        setCartToLocalStorage(updatedCart);
        setCart(updatedCart);
      }

      const updatedMenu = menuLocalItem.menu_category.map((meal) => {
        if (meal.cat_id === categoryId) {
          const updatedData = meal.data.map((item) => {
            if (item.id === itemId && item.qty > "0") {
              let qtyDecrement = 1;

              if (item.wholecat === "2") {
                qtyDecrement = 0.5;
              }

              return { ...item, qty: String(Number(item.qty) - qtyDecrement) };
            }
            return item;
          });

          return { ...meal, data: updatedData };
        }
        return meal;
      });

      localStorage.setItem(
        "menuLocal",
        JSON.stringify({ ...menuLocalItem, menu_category: updatedMenu })
      );
      setMenu({ menu_category: updatedMenu });
      setInitialMenu({ menu_category: updatedMenu });
      setIsMinusButtonDisabled(false);
      setAddButtonDisabled(false);
    }
  };
  const handlecartitemDecrement = (categoryId, itemId, cartIndex) => {
    const menuLocalItem = JSON.parse(localStorage.getItem("menuLocal"));

    const updatedMenu = menuLocalItem.menu_category.map((category) => {
      if (category.cat_id === categoryId) {
        const updatedData = category.data.map((item) =>
          item.id === itemId
            ? {
              ...item,
              qty: String(
                Number(item.qty) - (item.wholecat === "2" ? 0.5 : 1)
              ),
            }
            : item
        );
        return { ...category, data: updatedData };
      }
      return category;
    });

    const existingCartItem = cart.find(
      (cartItem) => cartItem.id === itemId && cartItem.category === categoryId
    );

    if (existingCartItem) {
      const updatedCart = cart.map((cartItem, index) =>
        index === cartIndex &&
          cartItem.category === categoryId &&
          cartItem.id === itemId
          ? {
            ...cartItem,
            qty: String(
              Number(cartItem.qty) - (cartItem.wholecat === "2" ? 0.5 : 1)
            ),
          }
          : cartItem
      );

      const updatedCartWithoutZeroQuantity = updatedCart.filter((cartItem) => {
        if (cartItem.wholecat === "2") {
          return cartItem.qty > "0";
        } else {
          // Remove the item if quantity becomes 0 or 1
          return cartItem.qty > "0";
        }
      });

      setCartToLocalStorage(updatedCartWithoutZeroQuantity);
      localStorage.setItem(
        "menuLocal",
        JSON.stringify({ ...menuLocalItem, menu_category: updatedMenu })
      );
      setMenu({ menu_category: updatedMenu });
      setCart(updatedCartWithoutZeroQuantity);
      setInitialMenu({ menu_category: updatedMenu });
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
  };
  const incrementNewItemQty = ({
    categoryId,
    itemId,
    selectedIngredients,
    selectedVariants,
    selectedArrayVariants,
  }) => {
    const menuLocalItem = JSON.parse(localStorage.getItem("menuLocal"));

    // Find the corresponding item in the menu data
    const newItem = menuLocalItem.menu_category
      .find((meal) => meal.cat_id === categoryId)
      .data.find((item) => item.id === itemId);
    console.log("jjj", newItem.wholecat);
    let qtyIncrement = 1;

    if (newItem.wholecat === "2") {
      qtyIncrement = 0.5;
    }

    const updatedCart = [
      ...cart,
      {
        ...newItem,
        qty: String(qtyIncrement),
        selectedIngredients,
        selectedVariants,
        selectedArrayVariants,
      },
    ];

    setCartToLocalStorage(updatedCart);

    setCart(updatedCart);

    const updatedMenu = menuLocalItem.menu_category.map((category) => {
      if (category.cat_id === categoryId) {
        const updatedData = category.data.map((item) =>
          item.id === itemId
            ? { ...item, qty: String(Number(item.qty) + qtyIncrement) }
            : item
        );
        return { ...category, data: updatedData };
      }
      return category;
    });

    localStorage.setItem(
      "menuLocal",
      JSON.stringify({ ...menuLocalItem, menu_category: updatedMenu })
    );
    setMenu({ menu_category: updatedMenu });
    setInitialMenu({ menu_category: updatedMenu });
  };
  const handlepromocoupon = (e) => {
    const value = e.target.value;
    setPromoText(value);
    localStorage.setItem("promoText", JSON.stringify(value)); // Save promoText to localStorage
  };
  const handleNewItem = (chooseItem) => {
    if (selectedMeat) {
      const { categoryId, itemId } = selectedMeat;
      setAddnew(true);
      // setCustomName(chooseItem)
      // console.log(chooseItem)

      fetchAddonsChoose({ categoryId, itemId, chooseItem });
    }
    setShowmodalBtn(false);
  };
  const handleRepeatIncrement = () => {
    if (selectedMeat) {
      const { categoryId, itemId } = selectedMeat;

      // Reverse the order of the cart to get the last item first
      const reversedCart = [...cart].reverse();

      // Find the index of the last occurrence of the item in the reversed cart
      const reversedCartIndex = reversedCart.findIndex(
        (cartItem) => cartItem.category === categoryId && cartItem.id === itemId
      );

      if (reversedCartIndex !== -1) {
        // Calculate the original index from the end
        const originalIndex = cart.length - 1 - reversedCartIndex;

        // If the item is found in the cart, update its quantity
        const updatedCart = cart.map((cartItem, index) => {
          if (index === originalIndex) {
            let qtyIncrement = 1; // Default quantity increment

            if (cartItem.wholecat === "2") {
              qtyIncrement = 0.5;
            }

            return {
              ...cartItem,
              qty: String(Number(cartItem.qty) + qtyIncrement),
            };
          }
          return cartItem;
        });

        const menuLocalItem = JSON.parse(localStorage.getItem("menuLocal"));
        const updatedMenu = menuLocalItem.menu_category.map((category) => {
          if (category.cat_id === categoryId) {
            const updatedData = category.data.map((item) =>
              item.id === itemId
                ? {
                  ...item,
                  qty: String(
                    Number(item.qty) + (item.wholecat === "2" ? 0.5 : 1)
                  ),
                }
                : item
            );
            return { ...category, data: updatedData };
          }
          return category;
        });

        localStorage.setItem(
          "menuLocal",
          JSON.stringify({ ...menuLocalItem, menu_category: updatedMenu })
        );
        setMenu({ menu_category: updatedMenu });
        setInitialMenu({ menu_category: updatedMenu });
        // Update local storage and state with the updated cart
        setCartToLocalStorage(updatedCart);
        setCart(updatedCart);
        setShowmodalBtn(false);
      } else {
        // If the item is not found in the cart, you may want to handle this case accordingly
        console.error("Item not found in the cart.");
      }
    }
  };

  const handleCart = () => {
    setShowcart(true);
  };
  //calculate total price
  let totalPrice;
  if (itemsInCart) {
    totalPrice = itemsInCart.reduce(
      (total, item) => total + item.price * Number(item.qty),
      0
    );
  }

  const incrementCartItemQty = (categoryId, itemId, cartIndex) => {
    const menuLocalItem = JSON.parse(localStorage.getItem("menuLocal"));
    const updatedMenu = menuLocalItem.menu_category.map((category) => {
      if (category.cat_id === categoryId) {
        const updatedData = category.data.map((item) =>
          item.id === itemId
            ? {
              ...item,
              qty: String(
                Number(item.qty) + (item.wholecat === "2" ? 0.5 : 1)
              ),
            }
            : item
        );
        return { ...category, data: updatedData };
      }
      return category;
    });

    const existingCartItem = cart.find(
      (cartItem) => cartItem.id === itemId && cartItem.category === categoryId
    );

    if (existingCartItem) {
      const updatedCart = cart.map((cartItem, index) =>
        index === cartIndex &&
          cartItem.category === categoryId &&
          cartItem.id === itemId
          ? {
            ...cartItem,
            qty: String(
              Number(cartItem.qty) + (cartItem.wholecat === "2" ? 0.5 : 1)
            ),
          }
          : cartItem
      );

      setCartToLocalStorage(updatedCart);
      localStorage.setItem(
        "menuLocal",
        JSON.stringify({ ...menuLocalItem, menu_category: updatedMenu })
      );
      setMenu({ menu_category: updatedMenu });
      setInitialMenu({ menu_category: updatedMenu });
      setCart(updatedCart);
    }
  };
  const handleIngredientChange = (ingredientName) => {
    setAddondata((prevData) => ({
      ...prevData,
      selectedIngredients: prevData.selectedIngredients.includes(ingredientName)
        ? prevData.selectedIngredients.filter((name) => name !== ingredientName)
        : [...prevData.selectedIngredients, ingredientName],
    }));
  };

  const handleContinue = () => {
    setShow(true);
    setShowcart(false);
  };
  const handleVariantChange = (heading, id, variantName, price) => {
    console.log("heading", heading);
    console.log("id", id);
    console.log("variantName", variantName);

    setAddondata((prevData) => ({
      ...prevData,
      selectedVariants: [
        ...prevData.selectedVariants.filter((v) => v.heading !== heading),
        { heading, id, selectedVariant: variantName, price: price },
      ],
    }));
  };

  const handleUpdateCartAddon = (
    indexid,
    categoryId,
    itemId,
    selectedIngredients,
    selectedVariants
  ) => {
    console.log("selectedIngredients", selectedIngredients);
    console.log("selectedVariants", selectedVariants);
    const reversedVariants = selectedVariants.slice().reverse();
    console.log("reversedVariants", reversedVariants);
    const cartData = JSON.parse(localStorage.getItem("cart")) || [];
    console.log(" cartData", cartData);
    const selectedItemIndex = cartData.findIndex(
      (item) =>
        item.category === categoryId &&
        item.id === itemId &&
        JSON.stringify(item.selectedIngredients) ===
        JSON.stringify(selectedIngredients) &&
        (JSON.stringify(item.selectedVariants) ===
          JSON.stringify(selectedVariants) ||
          JSON.stringify(item.selectedVariants) ===
          JSON.stringify(reversedVariants))
    );

    console.log("selectedItemIndex ", selectedItemIndex);
    if (selectedItemIndex !== -1 && selectedItemIndex !== indexid) {
      cartData[selectedItemIndex].qty = String(
        Number(cartData[selectedItemIndex].qty) + Number(cartData[indexid].qty)
      );

      if (indexid !== selectedItemIndex) {
        cartData.splice(indexid, 1);
      }

      localStorage.setItem("cart", JSON.stringify(cartData));
      setCart(cartData);
    } else {
      console.log("jj");

      const cartData = JSON.parse(localStorage.getItem("cart")) || [];

      const selectedItemIndexnon = indexid;

      if (selectedItemIndexnon !== -1) {
        cartData[selectedItemIndexnon].selectedIngredients =
          selectedIngredients || [];
        cartData[selectedItemIndexnon].selectedVariants =
          selectedVariants || [];

        localStorage.setItem("cart", JSON.stringify(cartData));
        setCart(cartData);

        setEditAddonModal(false);
      } else {
        console.error("Item not found in the cart");
      }
    }
    // Function to check if two arrays are equal

    setEditAddonModal(false);
  };

  const editAddons = async (categoryId, itemId, index) => {
    const url = `https://q2h.in/admin/api/ingredientsnew.php?category=${categoryId}&menu_id=${itemId}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (
        data &&
        data.variant &&
        Array.isArray(data.variant) &&
        data.variant.some(
          (variant) =>
            variant.data &&
            Array.isArray(variant.data) &&
            variant.data.length > 0
        )
      ) {
        const itemsInCart = JSON.parse(localStorage.getItem("cart")) || [];
        // Retrieve the item from the cart based on index
        const selectedItem = itemsInCart[index];

        // Set previously selected addons in the state
        setAddondata({
          ...data,
          selectedVariants:
            (selectedItem.selectedVariants &&
              selectedItem.selectedVariants.map((v) => ({ ...v }))) ||
            [],
          selectedArrayVariants:
            (selectedItem.selectedArrayVariants &&
              selectedItem.selectedArrayVariants.map((a) => a)) ||
            [],
          selectedIngredients:
            (selectedItem.selectedIngredients &&
              selectedItem.selectedIngredients.map((i) => i)) ||
            [],
        });
        setEditAddonModal(true);
        setSelectedMeat({ categoryId, itemId, index });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Invalid addons',
          text: 'No addons available',
        });
      }
    } catch (error) {
      console.error("Error fetching addon data:", error);
      // Handle error, show a message, or perform other actions as needed
    }
  };

  const handleCategoryClick = (categoryIndex) => {
    const categorySpan = document.getElementById("categorySpan");
    if (categorySpan) {
      categorySpan.scrollIntoView({ behavior: "smooth", block: "start" });

      setTimeout(() => {
        categorySpan.style.marginTop = "60px";
      }, 500); // Adjust the timeout value as needed
    }

    setSelectedCategory(categoryIndex);
    setAllitemsel(false);
    setChickensel(categoryIndex === 0);
    setMuttonsel(categoryIndex === 1);
  };

  const handleAllItemsClick = () => {
    setSelectedCategory(null);
    setAllitemsel(true);
    setChickensel(false);
    setMuttonsel(false);
  };

  //discount && coupon
  // const handlepromocoupon = (event) => {
  //   setPromoText(event.target.value);
  // };
  const fetchDatacoupon = async (branchId) => {
    const get_coupon = `https://q2h.in/admin/api/get_coupon.php?bid=${branchId}`;
    const data = await fetch(get_coupon);
    const res = await data.json();
    console.log({ res: res });
    localStorage.setItem("coupon", JSON.stringify(res.promo));
  };
  // const getPromocode = async () => {
  //   const res = JSON.parse(localStorage.getItem("coupon"));

  //   for (let i = 0; i < res.length; i++) {
  //     let usedUser = parseFloat(res[i].used_users) + 1;
  //     console.log({ usedUser: usedUser });

  //     // Create a new array with the updated used_users value
  //     const updatedCoupon = [
  //       ...res.slice(0, i),
  //       { ...res[i], used_users: String(usedUser) },
  //       ...res.slice(i + 1),
  //     ];

  //     localStorage.setItem("coupon", JSON.stringify(updatedCoupon));

  //     const TEXT = res[i].promo_code;
  //     const promo = promoText;

  //     if (TEXT === promo) {
  //       const item = res[i];

  //       if (item.promo_type === "percent") {
  //         setBillDiscount(
  //           parseFloat((totalPrice / 100) * parseFloat(item.discount_pa))
  //         );
  //       } else {
  //         setBillDiscount(parseFloat(item.discount_pa));
  //       }
  //       setCouponApplied(item);
  //     } else {
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Invalid promo code',
  //         text: 'Invalid promo code',
  //       });
  //     }
  //   }
  // };


  useEffect(() => {
    // Retrieve the promoText from localStorage when the component mounts
    const savedPromoText = JSON.parse(localStorage.getItem("promoText"));
    if (savedPromoText) {
      setPromoText(savedPromoText);
    }
  }, []);
  const getPromocode = async () => {
    const res = JSON.parse(localStorage.getItem("coupon"));

    for (let i = 0; i < res.length; i++) {
      let usedUser = parseFloat(res[i].used_users) + 1;
      console.log({ usedUser: usedUser });

      // Create a new array with the updated used_users value
      const updatedCoupon = [
        ...res.slice(0, i),
        { ...res[i], used_users: String(usedUser) },
        ...res.slice(i + 1),
      ];

      localStorage.setItem("coupon", JSON.stringify(updatedCoupon));

      const TEXT = res[i].promo_code;
      const promo = promoText;

      if (TEXT === promo) {
        const item = res[i];

        if (item.promo_type === "percent") {
          const discount = parseFloat(
            (totalPrice / 100) * parseFloat(item.discount_pa)
          );
          setBillDiscount(discount);
          localStorage.setItem("coupon_dis", discount);
        } else {
          const discount = parseFloat(item.discount_pa);
          setBillDiscount(discount);
          localStorage.setItem("coupon_dis", discount);
        }

        setCouponApplied(item);
      } else {
        alert("Invalid promo code");
      }
    }
  };
  const calcCrow = ({ lat1, lon1, lat2, lon2 }) => {
    function toRad(Value) {
      return (Value * Math.PI) / 180;
    }
    var Radius = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var calculate = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var distance = Radius * calculate;
    return Math.round(distance);
  };
  const getDeliveryDetails = async () => {
    const data = localStorage.getItem("branchid");
    const API = `https://q2h.in/admin/api/get_fssai.php?flag=4&branch=${data}`;
    const url = await fetch(API);
    const res = await url.json();
    getDeliveryCharges(res.data);
  };
  const getDeliveryCharges = async (delivery) => {
    const data = localStorage.getItem("branchid");
    const API = `https://q2h.in/admin/api/deliverycharge.php?bid=${data}`;
    const url = await fetch(API);
    const res = await url.json();
    const branchLatitude = parseFloat(delivery.lat);
    const branchLongitude = parseFloat(delivery.lng);

    const distance = calcCrow({
      lat1: latitude,
      lon1: longitude,
      lat2: branchLatitude,
      lon2: branchLongitude,
    });
    if (distance > Object.keys(res.charge).length) {
      const deliveryCharges = res.charge[Object.keys(res.charge).length - 1];
      setDeliveryCharges(deliveryCharges);
      setRefresh(!refresh);
    } else {
      const deliveryCharges = res.charge[distance <= 0 ? 1 : distance];
      setDeliveryCharges(deliveryCharges);
      setRefresh(!refresh);
    }
  };

  const getUserInfo = () => {
    const _id = localStorage.getItem("branchid");
    setBranchId(_id);
    const lat = localStorage.getItem("lat");
    setLatitude(lat);
    const long = localStorage.getItem("long");
    setLongitude(long);
    getDeliveryDetails();
  };

  const handleButtonClick = () => {
    if (deliveryType === "self") {
      setShowModal(false);
    } else if (!data || data.length === 0) {
      setShowModal(true);
      return;
    }
    // if (!data || data.length === 0  ) {
    //   setShowModal(true);
    //   return;
    // }
    if (totalPrice > branchStatus.min_amount || !data || data.length === 0) {
      setShowPaymentOptions(true);
      // setShowModal(true);
    } else {
      setAlart(true);
    }
  };
  // const getSubTotal = () => {
  //   let tempDeliveryCharges = 0;
  //   if (deliveryType == "home") {
  //     if (data) {
  //       tempDeliveryCharges += parseFloat(deliveryCharges);
  //     } else {
  //       tempDeliveryCharges = 0;
  //     }
  //   } else if (deliveryType == "self") {
  //     setDeliveryCharges(0);
  //     tempDeliveryCharges = 0;
  //   }
  //   let Discount = 0;
  //   if (
  //     totalPrice < couponApplied.limit_price ||
  //     totalPrice < couponApplied.discount_pa
  //   ) {
  //     setBillDiscount(0);
  //     setCouponApplied({});
  //   } else if (couponApplied.promo_type == "percent") {
  //     Discount = parseFloat(
  //       (totalPrice / 100) * parseFloat(couponApplied.discount_pa)
  //     );
  //     setBillDiscount(Discount);
  //   } else Discount = parseFloat(billDiscount);

  //   setBillTotal(totalPrice + tempDeliveryCharges + totalCusPrice - Discount);
  // };
  const getSubTotal = () => {
    let tempDeliveryCharges = 0;
    if (deliveryType == "home") {
      if (data) {
        tempDeliveryCharges += parseFloat(deliveryCharges);
      } else {
        tempDeliveryCharges = 0;
      }
    } else if (deliveryType == "self") {
      setDeliveryCharges(0);
      tempDeliveryCharges = 0;
    }
    let Discount = 0;
    if (
      totalPrice < couponApplied.limit_price ||
      totalPrice < couponApplied.discount_pa
    ) {
      setBillDiscount(0);
      setCouponApplied({});
    } else if (couponApplied.promo_type == "percent") {
      Discount = parseFloat(
        (totalPrice / 100) * parseFloat(couponApplied.discount_pa)
      );
      setBillDiscount(Discount);
    } else Discount = coupon_dis;

    setBillTotal(totalPrice + tempDeliveryCharges + totalCusPrice - Discount);
  };
  const handleCartEmpty = () => {
    setModalOpen(false);
  };

  const handleLogout = () => {
    navigate("/login");
    localStorage.clear(); // Use removeItem to remove the key
  };

  const cashonDelivery = async () => {
    localStorage.setItem("cart", JSON.stringify([]));
    localStorage.setItem("dataFetched", "false");
    setShowcart(false);
    await fetchDataOnce();
    setShowPaymentOptions(false);
    setShowLottie(false);
    setModalDelivery(true);
  };
  const handleItemClick = (menuItem) => {
    let itemId;
    const isMobileView = window.innerWidth < 768;

    if (isMobileView) {
      itemId = `mobile-item-${menuItem.id}`;
    } else {
      itemId = `lab-item-${menuItem.id}`;
    }

    const itemElement = document.getElementById(itemId);

    if (itemElement) {
      let scrollPosition;
      if (isMobileView) {
        const windowHeight = window.innerHeight;
        scrollPosition = itemElement.offsetTop - windowHeight / 4;
      } else {
        const windowHeight = window.innerHeight;
        scrollPosition = itemElement.offsetTop - windowHeight / 4;
      }

      if (isMobileView) {
        window.scroll(0, scrollPosition);
      } else {
        window.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
      }
    }

    setSearchResults([]);
    setSearchTerm("");
    setSelectedItemIndex(-1); // Reset the selected item index to -1
  };

  const handleKeyDown = (e) => {
    const isArrowUp = e.key === "ArrowUp";
    const isArrowDown = e.key === "ArrowDown";

    if (isArrowUp || isArrowDown) {
      e.preventDefault();

      if (selectedCategoryIndex === -1 && selectedCategoryIndex === -1) {
        // No item selected, select the first category and menu item
        setSelectedCategoryIndex(0);
        setSelectedItemIndex(0);
      } else {
        const categoryCount = searchResults.length;
        const itemCount =
          searchResults[selectedCategoryIndex]?.data?.length || 0;

        if (isArrowUp && selectedCategoryIndex > 0) {
          // Move to the previous category
          setSelectedCategoryIndex((prevIndex) => prevIndex - 1);
        } else if (isArrowDown && selectedCategoryIndex < categoryCount - 1) {
          // Move to the next category
          setSelectedCategoryIndex((prevIndex) => prevIndex + 1);
        } else if (isArrowUp && selectedItemIndex > 0) {
          // Move to the previous menu item
          setSelectedItemIndex((prevIndex) => prevIndex - 1);
        } else if (isArrowDown && selectedItemIndex < itemCount - 1) {
          // Move to the next menu item
          setSelectedItemIndex((prevIndex) => prevIndex + 1);
        }
      }
    } else if (e.key === "Enter" && selectedItemIndex !== -1) {
      // Handle Enter key press
      handleItemClick(
        searchResults[selectedCategoryIndex]?.data[selectedItemIndex]
      );
    }
  };

  useEffect(() => {
    const handleStorageChange = (event) => {
      console.log("Storage event triggered:", event);
      if (event.key === "cart") {
        memoizedFetchInitialMenu();
        console.log("Fetching menu data due to cart change.");
      }
    };

    // Add an event listener to listen for changes in 'menuLocal'
    window.addEventListener("storage", handleStorageChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [memoizedFetchInitialMenu]);

  useEffect(() => {
    if (otpGenerated) {
      const timer = setInterval(() => {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1);
        } else {
          setResendEnabled(true);
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [otpGenerated, timeLeft]);

  useEffect(() => {
    Token();
    handleBranchStatus();
  }, []);

  useEffect(() => {
    if (otpGenerated) {
      const timer = setInterval(() => {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1);
        } else {
          setResendEnabled(true);
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [otpGenerated, timeLeft]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) =>
        prevIndex === bannarImage.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);

    return () => clearInterval(interval);
  }, [bannarImage.length]);

  useEffect(() => {
    const data = localStorage.getItem("branchid");
    setBranchId(data);
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // console.log(searchResults)
  useEffect(() => {
    if (
      selectedCategoryIndex === -1 &&
      selectedItemIndex === -1 &&
      searchResults.length > 0
    ) {
      setSelectedCategoryIndex(0);
      setSelectedItemIndex(0);
    }

    return () => { };
  }, [searchResults, selectedCategoryIndex, selectedItemIndex]);

  useEffect(() => {
    const fetchData = async () => {
      if (branchId !== null) {
        await fetchDataOnce(branchId);
        await OverAllImage();
        fetchDatacoupon(branchId);

        fetchInitialMenu();
        getUserInfo();
      }
    };

    fetchData().catch((error) => console.error("Error:", error));
  }, [branchId]);

  //FOR ADDRESS
  useEffect(() => {
    if (cameFromAddressPage && itemsInCart.length > 0) {
      setShowcart(true);
    }
  }, [cameFromAddressPage, showCart]);

  useEffect(() => {
    getSubTotal();
  }, [totalPrice, billDiscount, deliveryCharges, totalCusPrice]);

  useEffect(() => {
    // Update the time whenever the value changes
    setTime(getFormattedDate(deliveryType === "self" ? 15 : 60));
  }, [deliveryType]);

  useEffect(() => {
    getUserInfo();
  }, [refresh]);
  const coupon_dis = localStorage.getItem("coupon_dis") || 0;
  // Calculate the custom price whenever the items in the cart change
  const calculateTotalCusPrice = () => {
    let total = 0;
    itemsInCart &&
      itemsInCart.forEach((item) => {
        if (item.qty > 0 && item.selectedVariants) {
          total += item.selectedVariants.reduce(
            (sum, variant) => sum + (parseFloat(variant.price) * item.qty || 0),
            0
          );
        }
      });
    return total;
  };

  // Update the custom price whenever the items in the cart change
  useEffect(() => {
    setTotalCusPrice(calculateTotalCusPrice());
  }, [itemsInCart]);
  const calculateCustomizedPrice = (item) => {
    return item.selectedVariants.reduce((total, variant) => {
      return total + parseFloat(variant.price) * item.qty;
    }, 0);
  };
  return (
    <div>
      <div className="model-head">
        {showCart && (
          <div className="modelBackground">
            <div className="modelContainer">
              <div className="title">
                <h3>Order Summary</h3>

                <button
                  className="titleCloseBtn"
                  onClick={() => {
                    setShowcart(false);
                    navigate("/Mainpage", {
                      state: { ...state, cameFromAddressPage: false },
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </div>

              <div>
                {itemsInCart.length > 0 ? (
                  <div>
                    <div className="p-2">
                      <div className="cart-boxes-shadow">
                        <div className="mb-3">
                          {itemsInCart.map(
                            (item, index) =>
                              item.qty > 0 && (
                                <div key={index} className="addons_card">
                                  <div className="addons_card2">
                                    {/* <p className="index_1">{index + 1}</p> */}
                                    <img
                                      src={item.foodtype === 1 ? veg : nonveg}
                                      alt="Non-Veg Food"
                                      className="img-veg-addons"
                                    />
                                    <p
                                      className="m-0"
                                      style={{ fontFamily: "heading" }}
                                    >
                                      {item.menu_name}
                                    </p>
                                  </div>
                                  <div className="pb-3">
                                    <div className="addons_flex">
                                      <p
                                        className="m-0"
                                        style={{ fontFamily: "heading" }}
                                      >
                                        ₹{item.dprice}
                                      </p>

                                      {item.qty > 0 && (
                                        <div className="add_btn_out">
                                          <button
                                            className="quantity-btn"
                                            onClick={() => {
                                              handlecartitemDecrement(
                                                item.category,
                                                item.id,
                                                index
                                              );
                                            }}
                                          >
                                            -
                                          </button>
                                          {/* <h6 className="p-1 m-1">{item.qty}</h6> */}
                                          {loadingItems[index] ? (
                                            <div className="loading-spinner-container">
                                              <LoadingSpinner />
                                            </div>
                                          ) : (
                                            <h6 className="p-1 m-1">
                                              {item.qty}
                                            </h6>
                                          )}
                                          <button
                                            className="quantity-btn"
                                            onClick={() => {
                                              incrementCartItemQty(
                                                item.category,
                                                item.id,
                                                index
                                              );
                                            }}
                                          >
                                            +
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                    <div className="cus-1">
                                      {item.ingrecount === 1 && !item.start ? (
                                        <p className="cus-2">
                                          Customizable Price: ₹
                                          {calculateCustomizedPrice(item)}
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                      {item.ingrecount === 1 && !item.start ? (
                                        <p
                                          className="customize"
                                          onClick={() =>
                                            editAddons(
                                              item.category,
                                              item.id,
                                              index
                                            )
                                          }
                                        >
                                          Customizable
                                        </p>
                                      ) : (
                                        <div></div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                        <div>
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Add instructions"
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <div className="cart-boxes-shadow pt-3 pb-3 ">
                          <h3
                            style={{
                              color: "#000",
                              fontSize: "16px",

                              fontFamily: "branch-name",
                            }}
                          >
                            Delivery Type
                          </h3>
                          <div className="d-flex  justify-content-between">
                            <div className="">
                              <label
                                className=""
                                style={{
                                  color: "#000",
                                  cursor: "pointer",
                                  fontFamily: "delivery",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  name="deliveryType"
                                  checked={deliveryType === "self"}
                                  onChange={() => {
                                    setDeliveryType("self");
                                    setDeliveryCharges(0);
                                  }}
                                />{" "}
                                Self Pickup
                              </label>
                            </div>
                            <div className="form-check ">
                              <label
                                className="form-check-label"
                                style={{
                                  color: "#000",
                                  cursor: "pointer",
                                  fontFamily: "delivery",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  name="deliveryType"
                                  checked={deliveryType === "home"}
                                  onChange={() => setDeliveryType("home")}
                                />{" "}
                                Home Delivery
                              </label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <h3
                              style={{
                                color: "#000",
                                fontSize: "16px",
                                marginBottom: "12px",
                                fontFamily: "branch-name",
                              }}
                            >
                              Delivery Date and Time
                            </h3> */}
                        </div>
                        <div className="form-date-time cart-boxes-shadow">
                          <div className="input-data-time">
                            <div>
                              <label htmlFor="deliveryDate">
                                Delivery Date:
                              </label>
                              <input
                                className="form-control"
                                type="date"
                                id="deliveryDate"
                                value={date.toISOString().split("T")[0]}
                                onChange={handleDateChange}
                              />
                            </div>
                            <div>
                              <label htmlFor="deliveryTime">
                                Delivery Time:
                              </label>
                              <input
                                className="form-control"
                                type="time"
                                id="deliveryTime"
                                value={time.toTimeString().split(" ")[0]}
                                onChange={handleTimeChange}
                              />
                            </div>
                          </div>
                          <div className="">
                            <span
                              style={{
                                color: "orange",
                                fontWeight: "600",
                              }}
                            >
                              {" "}
                              Expected Delivery  :
                            </span>
                            <span
                              style={{
                                color: "orange",
                                fontWeight: "500",
                                margin: "3px",
                                padding: "2px",
                              }}
                            >
                              {date.toLocaleDateString()}
                            </span>
                            <span
                              style={{
                                color: "orange",
                                fontWeight: "500",
                              }}
                            >
                              {time && time instanceof Date && !isNaN(time)
                                ? time.toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true,
                                })
                                : ""}
                            </span>
                          </div>
                          {/* <div className="item2total mt-2">
                            <p
                              className=""
                              style={{
                                fontFamily: "heading",
                                margin: "5px",
                                fontSize: "20px",
                              }}
                            >
                              Coupon
                            </p>
                            <input
                              type="text"
                              onChange={handlepromocoupon}
                              value={promoText}
                              className="coupon-change"
                              style={{
                                width: "40%",
                                marginLeft: "20px",
                              }}
                            />
                            <button
                              className="btn-apply"
                              onClick={() => {
                                if (billDiscount <= 0) {
                                  getPromocode();
                                } else {
                                  setBillDiscount(0);
                                  setCouponApplied({});
                                  setPromoText("");
                                }
                              }}
                            >
                              {billDiscount <= 0 ? "APPLY" : "REMOVE"}
                            </button>
                          </div> */}
                            <div className="item2total mt-2">
                            <p
                              className=""
                              style={{
                                fontFamily: "heading",
                                margin: "5px",
                                fontSize: "20px",
                              }}
                            >
                              Coupon
                            </p>
                            <input
                              type="text"
                              onChange={handlepromocoupon}
                              value={promoText}
                              className="coupon-change"
                              style={{
                                width: "40%",
                                marginLeft: "20px",
                              }}
                            />
                            <button
                              className="btn-apply"
                              onClick={() => {
                                if (billDiscount <= 0) {
                                  getPromocode();
                                } else {
                                  setBillDiscount(0);
                                  setCouponApplied({});
                                  setPromoText("");
                                  localStorage.setItem(
                                    "promoText",
                                    JSON.stringify("")
                                  ); // Clear promoText in localStorage
                                  localStorage.setItem("coupon_dis", 0);
                                }
                              }}
                            >
                              {coupon_dis <= 0 ? "APPLY" : "REMOVE"}
                            </button>
                          </div>
                          {deliveryType == "home" && log === "true" ? (
                            <div
                              style={{
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                padding: "10px",
                                borderRadius: "8px",
                                marginTop: "15px",
                              }}
                            >
                              <div
                                className={`d-flex ${data ? 'justify-content-between' : 'justify-content-end'}`}
                              >
                                {data ? (
                                  <>
                                    <p
                                      style={{ fontFamily: 'heading' }}
                                      onClick={() => navigate('/address')}
                                    >
                                      Address
                                    </p>
                                    <button
                                      onClick={() => {
                                        navigate('/address', {
                                          state: {
                                            Id: data.id,
                                          },
                                        });
                                      }}
                                      className="btn-change"
                                    >
                                      Change
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    onClick={() => navigate('/address')}
                                    className="btn-sub"
                                  >
                                    Add Address
                                  </button>
                                )}
                              </div>

                              <ul
                                style={{
                                  listStyle: "none",
                                  fontFamily: "delivery",
                                  margin: 0,
                                  padding: 0,
                                }}
                              >
                                {data && (
                                  <>
                                    <li style={{ fontFamily: "delivery" }}>
                                      {data.name}
                                    </li>
                                    <li style={{ fontFamily: "description" }}>
                                      {data.line1}
                                    </li>
                                  </>
                                )}
                              </ul>
                            </div>
                          ) : null}
                        </div>

                        <div className="bill_details p-2">
                          <h3>Bill Details</h3>
                          <div className="item_total">
                            <p>Item Total</p>
                            <p>{`\u20B9${Math.round(totalPrice)}`}</p>
                          </div>
                          <div className="item_total">
                            <p>Customizable Price</p>
                            <p>{`\u20B9${totalCusPrice}`}</p>
                          </div>
                          {deliveryType === "home" && data && (
                            <div className="item_total">
                              <p>Delivery Charges</p>
                              <p>{`\u20B9${deliveryCharges}`}</p>
                            </div>
                          )}
                          <div className="item_total">
                            <p>Coupon</p>
                            <p style={{color:"orange"}}>
                              {coupon_dis === 0
                                ? `\u20B9${coupon_dis}`
                                : `\u20B9${Math.round(coupon_dis)}`}
                            </p>
                          </div>
                          <div className="item_total_1">
                            <h6>Total</h6>
                            <h6>{`\u20B9${billTotal.toString()}`}</h6>
                          </div>
                        </div>

                        {showPaymentOptions && (
                          <div className="card m-2 ">
                            <div className="lin-or position-relative">
                              <h5
                                style={{
                                  textAlign: "center",
                                  fontFamily: "heading",
                                }}
                              >
                                Select your Payment
                              </h5>
                            </div>
                            {/* <hr className="lin-or-line" /> */}
                            <div className="two-buttons"
                             
                            >
                              {branchStatus.onlinpayment === 1 &&
                                branchStatus.cashfree === 1 && (
                                  <div className="addons_card11">
                                    <img
                                      src={cashfreeImage}
                                      alt="img"
                                      className="razorpay"
                                      htmlFor="cashfree"
                                      onClick={() => {
                                        handlePaymentMethod({
                                          gateway: "cashfree",
                                          paymentType: "card",
                                        });
                                      }}
                                    />
                                  </div>
                                )}
                              {branchStatus.onlinpayment === 1 &&
                                branchStatus.razorpay === 1 && (
                                  <div>
                                  <button  onClick={() => {
                                        handlePaymentMethod({
                                          gateway: "razorpay",
                                          paymentType: "card",
                                        });
                                      }} className="online-p">
                                        Online Payment
                                  </button>
                                     
                                  
                                  
                                  </div>
                                )}
                              {branchStatus.home_delivery === 1 &&
                                branchStatus.cashon === 1 && (
                                  <div>
                                    <button onClick={() => {
                                        handlePaymentMethod({
                                          paymentType: "cash",
                                        });
                                      }}
                                      className="online-p" >
                                        Cash On Delivery
                                    </button>
                                      
                                  </div>
                                )}
                            </div>
                          </div>
                        )}
                        <div className="logen_stricky">
                          {isLoggedIn === "true" ? (
                            <div className="d-flex flex-column">
                              <button
                                className="log_to_cont"
                                onClick={handleButtonClick}
                              >
                                Proceed To Checkout
                              </button>
                              {/* <button
                                  className="log_to_cont "
                                  onClick={handleOnlinePayment}
                                >
                                  payment
                                </button> */}
                            </div>
                          ) : (
                            <div className="logen_stricky">
                              <button
                                className="log_to_cont"
                                onClick={() => handleContinue()}
                              >
                                Login To Continue
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="img-cart p-3">
                      {/* <img
                          src={q2h}
                          alt="img"
                          className="img-fluid"
                          onClick={() => navigate("/BranchPage")}
                        /> */}
                    </div>
                    <div className="d-flex justify-content-center">
                      <img
                        src={cartimg}
                        className="img-addtocart img-fluid"
                        alt="Cart"
                      />
                    </div>

                    <div className="d-flex flex-column align-items-center">
                      <h4 className="bold" style={{ fontFamily: "delivery" }}>
                        Your Cart is empty
                      </h4>
                      <h6>You can go to the main page to add products</h6>
                      <button
                        className="btn-order"
                        onClick={() => {
                          setShowcart(false);
                        }}
                      >
                        Order Now
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={showCart ? "mh-head-1" : "mh-head"}>
        <div className="home_head61">
          <div className="img-cart p-2" onClick={handleImgClick}>
            <img src={q2h} alt="img" className="img_fluid" />
          </div>
          <div className="container-fluid">
            <div className="mt-3">
              <div className="home_headright">
                <div className="search-input-container">
                  <FaSearch className="search-icon" />
                  <div ref={searchResultsRef}>
                    <ul>
                      {searchResults.map((category, categoryIndex) => (
                        <li
                          key={category.cat_id}
                          className={
                            categoryIndex === selectedCategoryIndex
                              ? "selected"
                              : ""
                          }
                        >
                          {/* <h4>{category.cat_name}</h4> */}
                          <div className="search-results-dropdown">
                            <ul>
                              {category.data.map((menuItem, itemIndex) => (
                                <li
                                  key={menuItem.id}
                                  className={
                                    itemIndex === selectedItemIndex
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={() => handleItemClick(menuItem)}
                                  ref={
                                    itemIndex === selectedItemIndex
                                      ? scrollToItemRef
                                      : null
                                  }
                                >
                                  {menuItem.menu_name}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <input
                    type="text"
                    placeholder="Search items"
                    value={searchTerm}
                    onChange={handleSearch}
                    onKeyDown={handleKeyDown}
                    className="search-input"
                  />
                </div>

                <div className="home_headright_1">
                  <div className="cart_items">
                    <PiHouse
                      className="profile_icon"
                      onClick={() => navigate("/branchpage")}
                    />
                  </div>
                  {/* {loginame && 
                      <p className="loginame">{loginame}</p>
                     
                      }  */}
                  <div className="darksoul-dropdown">
                    <PiUserCircle className="profile_icon" />

                    {/* <FaUserCircle  /> */}
                    <div className="darksoul-dropdown-content">
                      {isLoggedIn ? (
                        <>
                          <p onClick={() => navigate("/orders")}>Orders</p>
                          <p onClick={() => navigate("/address")}>Address</p>
                          <p onClick={() => navigate("/contact")}>Contact</p>
                          <p onClick={() => navigate("/feedback")}>Feedback</p>
                          <p onClick={() => handleLogout()}>Log Out</p>
                        </>
                      ) : (
                        <>
                          <p onClick={() => handleContinue()}>Login</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="cart_items">
                    <p
                      className={`cart ${isCartShaking ? "shake" : ""}`}
                      // data-totalitems={cartTotal}
                      onClick={() => handleCart()}
                    >
                      <div className="cart-icon-container">
                        <PiShoppingCartSimpleLight
                          size={30}
                          color="black"
                          className="cart-icon  m-1"
                        />
                        {totalCount > 0 && (
                          <span className="cart-items-count">{totalCount}</span>
                        )}
                      </div>
                    </p>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        {showLottie && (
        <div className="lottie-overlay">
          <Lottie
            width={250}
            height={250}
            options={{
              loop: true,
              autoplay: true,
              animationData: require('../../Assests/Animation - 1724330034520.json'),
            }}
          />
        </div>
      )}
        <div className="Carousel_Header">
          <div lassName="" style={{ width: "90%" }}>
            <Carousel activeIndex={index} {...settings}>
              {bannarImage.map((image, index) => (
                <Carousel.Item key={index}>
                  <img
                    style={{ height: "400px", borderRadius: "15px" }}
                    className="d-block w-100"
                    src={`https://q2h.in/admin/images/banner/${image.img}`}
                    alt={`Slide ${index + 1}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
        <div className="m-3 ">
          {todaySpecial &&
            (todaySpecial.todaysspl.length > 0 ||
              todaySpecial.top_picks.length > 0) && (
              <>
                {todaySpecial.todaysspl.length > 0 && (
                  <div className="lin-or position-relative m-2">
                    <span style={{ textAlign: "center" }}>Today's Special</span>
                  </div>
                )}

                <div>
                  <div className="d-flex flex-wrap justify-content-around">
                    {/* Render today's special items */}
                    {todaySpecial.todaysspl.map((item) => (
                      <div
                        key={item.id}
                        className="rounded-container col-6 col-md-4 col-lg-3 text-center"
                      >
                        <img
                          className="rounded-image"
                          src={`${menu_item_image}${item.menu_image}`}
                          alt={item.menu_name}
                        />
                        <h6>{item.menu_name}</h6>
                        {/* Add more details as needed */}
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
        </div>

        <Modal
          show={alart}
          dialogClassName="right-enter-modal modal-dialog-centered"
          animation={false}
        >
          <Modal.Body className="blink-animation">
            <h6 className="order-amount">
              The minimum order amount required to place an order should be{" "}
              {`\u20B9${branchStatus.del_max_amount}`} or higher.
            </h6>
            <div className="d-flex justify-content-end align-items-end">
              <button
                className="add_btn close-btn"
                onClick={() => {
                  setAlart(false);

                }}
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <div className="">
          <Modal
            show={show}
            onHide={handleCloose}
            dialogClassName="right-enter-modal"
            animation={false}
          >
            <Modal.Header style={{ border: "none" }} closeButton>
              <Modal.Title> </Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-modal-body">
              <div>
                {isLoggedIn === "true" ? (
                  <div className="l_and_s">
                    <div>
                      <img src={q2h} alt="img" className="img_fluid_69" />
                    </div>
                    <div className="mod_lg_sg">
                      {showLogin && (
                        <button onClick={handleLogin} className="btnlogin_69">
                          Login
                        </button>
                      )}
                      {showSignup && (
                        <button onClick={handleSignin} className="btnlogin_69">
                          Signup
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="l_and_s">
                    <div>
                      <img src={q2h} alt="img" className="img_fluid_69" />
                    </div>
                    <div className="mod_lg_sg">
                      {showLogin && (
                        <button onClick={handleLogin} className="btnlogin_69">
                          Login
                        </button>
                      )}
                      {showSignup && (
                        <button onClick={handleSignin} className="btnlogin_69">
                          Signup
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {isLogin && (
                  <div className="log_top">
                    <div className="log_top_69">
                      {/* <div>
                        <span className="sp_91">+91</span>
                      </div> */}
                      <div>
                        <input
                          type="tel"
                          placeholder="Enter your mobile number"
                          className="input_des_699"
                          onChange={handleMobileNumberChange}
                          disabled={inputDisabled}
                          maxLength={10}
                          onKeyDown={(e) => {
                            // Allow only numbers to be typed
                            const isNumberKey = e.key >= '0' && e.key <= '9';
                            const isBackspaceKey = e.key === 'Backspace';
                            const isArrowKey = e.key === 'ArrowLeft' || e.key === 'ArrowRight';
                            const isDeleteKey = e.key === 'Delete';
                            const isTabKey = e.key === 'Tab';
                            const isNumPadKey = e.keyCode >= 96 && e.keyCode <= 105; // Num Lock number keys

                            // Prevent input if the key pressed is not a number, Backspace, arrow key, Delete, Num Lock number keys, or Tab
                            if (
                              !isNumberKey &&
                              !isBackspaceKey &&
                              !isArrowKey &&
                              !isDeleteKey &&
                              !isTabKey &&
                              !isNumPadKey
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    {errorMessage && (
                      <p style={{ color: "red" }}>{errorMessage}</p>
                    )}
                    <button
                      className="btnlogin_69"
                      style={{ backgroundColor: enabled ? "#dddd" : "#f48407" }}
                      onClick={handleLgOtp}
                      disabled={buttonDisabled}
                    >
                      Login
                    </button>
                    <p className="dhc">
                      Don't have an account?{" "}
                      <span className="dhsp" onClick={handleSignin}>
                        Signup
                      </span>
                    </p>
                  </div>
                )}
                {showSignupForm && (
                  <div className="signup_align_69">
                    <input
                      type="text"
                      placeholder="Enter your name"
                      value={name}
                      maxLength={20}
                      disabled={inputDisabled}
                      className="input_des_69"
                      onChange={handleNameChange}
                    />

                    <input
                      type="tel"
                      placeholder="Enter your mobile number"
                      disabled={inputDisabled}
                      value={mobileNumber}
                      maxLength={10}
                      className="input_des_69"
                      onChange={handleInputChange}
                      onBlur={handleNOBlur}
                      onKeyDown={(e) => {
                        // Allow only numbers to be typed
                        const isNumberKey = e.key >= '0' && e.key <= '9';
                        const isBackspaceKey = e.key === 'Backspace';
                        const isArrowKey = e.key === 'ArrowLeft' || e.key === 'ArrowRight';
                        const isDeleteKey = e.key === 'Delete';
                        const isTabKey = e.key === 'Tab';
                        const isNumPadKey = e.keyCode >= 96 && e.keyCode <= 105; // Num Lock number keys

                        // Prevent input if the key pressed is not a number, Backspace, arrow key, Delete, Num Lock number keys, or Tab
                        if (
                          !isNumberKey &&
                          !isBackspaceKey &&
                          !isArrowKey &&
                          !isDeleteKey &&
                          !isTabKey &&
                          !isNumPadKey
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {error && <p style={{ color: "red" }}>{error}</p>}

                    <input
                      type="email"
                      placeholder="Enter your email"
                      required
                      className="input_des_69"
                      disabled={inputDisabled}
                      value={email}
                      onChange={handleEmailChange}
                      onBlur={handleEmailBlur}
                      
                    />
                     {emailError && <p style={{ color: "red" }}>{emailError}</p>}

                    <button
                      className="btnlogin_69"
                      style={{ backgroundColor: enabled ? "#dddd" : "#f48407" }}
                      onClick={() => handleSignup()}
                      disabled={buttonDisabled}
                    >
                      Sign Up
                    </button>
                    <p className="login_color" onClick={backupLogin}>
                      <span className="bo_bot">Login ?</span>
                    </p>
                    {/* <button onClick={handleBackToMain}>Back to Main</button> */}
                  </div>
                )}
                {otp && (
                  <div className="modal_11">
                    <div className="shadow_11">
                      <div className="modal_content_11">
                        <div className="otp-input p-2">
                          <input
                            type="tel"
                            placeholder="0"
                            maxLength={1}
                            className="otp-box"
                            required
                            ref={otpInputRefs[0]}
                            onChange={(e) => handleOTPInputChange(e, 0)}
                            autoFocus
                            onKeyDown={(e) => {
                              const pattern = /[0-9]/;
                              const charCode = e.which ? e.which : e.keyCode;
                              const charTyped = String.fromCharCode(charCode);

                              // Allow numeric keys (top row and numeric keypad)
                              if (
                                (charCode >= 48 && charCode <= 57) ||
                                (charCode >= 96 && charCode <= 105)
                              ) {
                                return;
                              }

                              // Allow Backspace
                              if (
                                e.key === "Backspace" &&
                                e.target.value === ""
                              ) {
                                const currentIndex = otpInputRefs.findIndex(
                                  (ref) => ref.current === e.target
                                );
                                if (currentIndex > 0) {
                                  e.preventDefault();
                                  otpInputRefs[
                                    currentIndex - 1
                                  ].current.focus();
                                  otpInputRefs[currentIndex - 1].current.value =
                                    "";
                                }
                              } else {
                                e.preventDefault();
                              }
                            }}
                          />
                          <input
                            type="tel"
                            placeholder="0"
                            maxLength={1}
                            className="otp-box"
                            required
                            ref={otpInputRefs[1]}
                            onChange={(e) => handleOTPInputChange(e, 1)}
                            onKeyDown={(e) => {
                              const pattern = /[0-9]/;
                              const charCode = e.which ? e.which : e.keyCode;
                              const charTyped = String.fromCharCode(charCode);

                              // Allow numeric keys (top row and numeric keypad)
                              if (
                                (charCode >= 48 && charCode <= 57) ||
                                (charCode >= 96 && charCode <= 105)
                              ) {
                                return;
                              }

                              // Allow Backspace
                              if (
                                e.key === "Backspace" &&
                                e.target.value === ""
                              ) {
                                const currentIndex = otpInputRefs.findIndex(
                                  (ref) => ref.current === e.target
                                );
                                if (currentIndex > 0) {
                                  e.preventDefault();
                                  otpInputRefs[
                                    currentIndex - 1
                                  ].current.focus();
                                  otpInputRefs[currentIndex - 1].current.value =
                                    "";
                                }
                              } else {
                                e.preventDefault();
                              }
                            }}
                          />
                          <input
                            type="tel"
                            placeholder="0"
                            maxLength={1}
                            className="otp-box"
                            required
                            ref={otpInputRefs[2]}
                            onChange={(e) => handleOTPInputChange(e, 2)}
                            onKeyDown={(e) => {
                              const pattern = /[0-9]/;
                              const charCode = e.which ? e.which : e.keyCode;
                              const charTyped = String.fromCharCode(charCode);

                              // Allow numeric keys (top row and numeric keypad)
                              if (
                                (charCode >= 48 && charCode <= 57) ||
                                (charCode >= 96 && charCode <= 105)
                              ) {
                                return;
                              }

                              // Allow Backspace
                              if (
                                e.key === "Backspace" &&
                                e.target.value === ""
                              ) {
                                const currentIndex = otpInputRefs.findIndex(
                                  (ref) => ref.current === e.target
                                );
                                if (currentIndex > 0) {
                                  e.preventDefault();
                                  otpInputRefs[
                                    currentIndex - 1
                                  ].current.focus();
                                  otpInputRefs[currentIndex - 1].current.value =
                                    "";
                                }
                              } else {
                                e.preventDefault();
                              }
                            }}
                          />

                          <input
                            type="tel"
                            placeholder="0"
                            maxLength={1}
                            required
                            className="otp-box"
                            ref={otpInputRefs[3]}
                            onChange={(e) => handleOTPInputChange(e, 3)}
                            onKeyDown={(e) => {
                              // Allow only numbers to be typed
                              const pattern = /[0-9]/;
                              const charCode = e.which ? e.which : e.keyCode;
                              const charTyped = String.fromCharCode(charCode);
                              if (
                                (charCode >= 48 && charCode <= 57) ||
                                (charCode >= 96 && charCode <= 105)
                              ) {
                                return;
                              }
                              // Allow only numbers and backspace
                              if (!pattern.test(charTyped) && charCode !== 8) {
                                e.preventDefault();
                              }

                              // Handle backspace to delete the current value and move back to the previous input field
                              if (charCode === 8 && e.target.value === "") {
                                // Get the index of the current input
                                const currentIndex = otpInputRefs.findIndex(
                                  (ref) => ref.current === e.target
                                );
                                if (currentIndex > 0) {
                                  // Move focus to the previous input
                                  otpInputRefs[
                                    currentIndex - 1
                                  ].current.focus();
                                  // Clear the value of the previous input
                                  otpInputRefs[currentIndex - 1].current.value =
                                    "";
                                }
                              }
                            }}
                          />
                        </div>
                        <p className="otpcolor">
                          {otpGenerated
                            ? ` ${timeLeft} seconds`
                            : "OTP not generated"}
                        </p>
                        <p className="edit" onClick={handleCancelOtp}>
                          Change Number
                        </p>
                        {otpGenerated && isVerifyButton && timeLeft ? (
                          <button
                            className="btn_11"
                            onClick={() => handleOtpVerify()}
                          >
                            Verify OTP
                          </button>
                        ) : (
                          <div>
                            {/* <button className="btn_12" >Submit</button> */}
                            <button
                              className={`btn_11 ${resendEnabled ? "" : "disabled"
                                }`}
                              onClick={handleResendOTP}
                              disabled={!resendEnabled}
                            >
                              Resend OTP
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer style={{ border: "none" }}></Modal.Footer>
          </Modal>
        </div>
        {/* START ADDONS --Customized */}
        <div className="image-container" id="categorySpan">
          {menu && menu.menu_category && menu.menu_category.length > 1 ? (
            branchId == 8 ? (
              <div className="  gap">
                <div className="header-topics">
                  <img
                    src={chickenmutton}
                    className="rounded-image"
                    alt="All Items"
                    onClick={() => handleAllItemsClick()}
                  />
                  {allitemsel ? (
                    <p className="p-2 text-clr">All items</p>
                  ) : (
                    <p className="p-2 ">All items</p>
                  )}
                </div>

                <div className="header-topics" tabIndex="0">
                  <img
                    src={chicken}
                    className="rounded-image"
                    alt="Chicken"
                    onClick={() => handleCategoryClick(1, "auto")}
                  />
                  {muttonsel ? (
                    <p className="p-2 text-clr">Chicken</p>
                  ) : (
                    <p className="p-2 ">Chicken</p>
                  )}
                </div>

                <div className="header-topics">
                  <img
                    src={mutton}
                    className="rounded-image"
                    alt="Mutton"
                    onClick={() => handleCategoryClick(0)}
                  />
                  {chickensel ? (
                    <p className="p-2 text-clr">Mutton</p>
                  ) : (
                    <p className="p-2 ">Mutton</p>
                  )}
                </div>
                {/* <div className="header-topics">
                  <img
                    src={fish}
                    className="rounded-image"
                    alt="Fish"
                  // onClick={() => handleAllItemsClick()}
                  />
                  <p className="p-2 ">Fish</p>
                </div>
                <div className="header-topics">
                  <img
                    src={Quail}
                    className="rounded-image"
                    alt="Kadai"
                  // onClick={() => handleAllItemsClick()}
                  />
                  <p className="p-2 ">Kadai</p>
                </div>
                <div className="header-topics">
                  <img
                    src={eggs}
                    className="rounded-image"
                    alt="Eggs"
                  // onClick={() => handleAllItemsClick()}
                  />
                  <p className="p-2 ">Eggs</p>
                </div>
                <div className="header-topics">
                  <img
                    src={Quailegg}
                    className="rounded-image"
                    alt="Kadai Egg"
                  // onClick={() => handleAllItemsClick()}
                  />
                  <p className="p-2 ">Kadai Egg</p>
                </div> */}
              </div>
            ) : (
              <div className=" gap">
                <div className="header-topics">
                  <img
                    src={chickenmutton}
                    className="rounded-image"
                    alt="All Items"
                    onClick={() => handleAllItemsClick()}
                  />
                  {allitemsel ? (
                    <p className="p-2 text-clr">All Item</p>
                  ) : (
                    <p className="p-2 ">All Item</p>
                  )}
                </div>

                <div className="header-topics">
                  <img
                    src={chicken}
                    className="rounded-image"
                    alt="Chicken"
                    onClick={() => handleCategoryClick(0)}
                  />
                  {chickensel ? (
                    <p className="p-2 text-clr">Chicken</p>
                  ) : (
                    <p className="p-2 ">Chicken</p>
                  )}
                </div>

                <div className="header-topics">
                  <img
                    src={mutton}
                    className="rounded-image"
                    alt="Mutton"
                    onClick={() => handleCategoryClick(1)}
                  />
                  {muttonsel ? (
                    <p className="p-2 text-clr">Mutton</p>
                  ) : (
                    <p className="p-2 ">Mutton</p>
                  )}
                </div>
                {/* <div className="header-topics">
                  <img
                    src={fish}
                    className="rounded-image"
                    alt="Fish"
                  // onClick={() => handleAllItemsClick()}
                  />
                  <p className="p-2 ">Fish</p>
                </div>
                <div className="header-topics">
                  <img
                    src={Quail}
                    className="rounded-image"
                    alt="Fish"
                  // onClick={() => handleAllItemsClick()}
                  />
                  <p className="p-2 ">Kadai</p>
                </div>
                <div className="header-topics">
                  <img
                    src={eggs}
                    className="rounded-image"
                    alt="Eggs"
                  // onClick={() => handleAllItemsClick()}
                  />
                  <p className="p-2 ">Eggs</p>
                </div>
                <div className="header-topics">
                  <img
                    src={Quailegg}
                    className="rounded-image"
                    alt="Kadai Egg"
                  // onClick={() => handleAllItemsClick()}
                  />
                  <p className="p-2 ">Kadai Egg</p>
                </div> */}
              </div>
            )
          ) : (
            <div></div>
          )}
        </div>
        <div className="category m-4  res-top-disp">
          <div className="card_1 ">
            {menu.menu_category &&
              menu.menu_category.map((category, index) => (
                <div
                  key={index}
                  className="category_item"
                  style={{
                    display:
                      selectedCategory === index || selectedCategory === null
                        ? "block"
                        : "none",
                  }}
                >
                  <div className="lin-or position-relative m-3">
                    <span
                      style={{ textAlign: "center", fontFamily: "heading" }}
                    >
                      {category.data.length == 0 ? (
                        <></>
                      ) : (
                        <div>{category.cat_name}</div>
                      )}
                    </span>
                  </div>
                  <div className="card_1 ">
                    {category.data &&
                      category.data.map((item, itemIndex) => {
                        return (
                          <div
                            className="card_2"
                            key={itemIndex}
                            id={`lab-item-${item.id}`}
                          >
                            <div className=" card_3 ">
                              <img
                                src={
                                  item.menu_image
                                    ? `${menu_item_image}${item.menu_image}`
                                    : branch
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = branch;
                                }}
                                style={{
                                  backgroundColor:
                                    item.status === "1"
                                      ? ""
                                      : "dark-background",
                                  filter:
                                    item.status === "1"
                                      ? "none"
                                      : "grayscale(100%)",
                                }}
                                width={100}
                                className={`cartt_image ${item.status === "1" ? "" : "dark-background"
                                  }`}
                                alt={item.menu_name}
                              />
                              <div className="cart_4">
                                <div>
                                  <div className="food-image">
                                    <div className="">
                                      <img
                                        src={item.foodtype === 1 ? veg : nonveg}
                                        alt="Non-Veg Food"
                                        className="img-veg"
                                      />
                                    </div>
                                    <h4 className="menu-name_m p-1">
                                      {item.menu_name}
                                    </h4>
                                  </div>
                                  <h6 className="description m-2">
                                    {item.description}
                                  </h6>
                                </div>
                                <div className="btn-price">
                                  <div className="price-and-button">
                                    {item.dprice ? (
                                      <>
                                        {item.discount > 0 && (
                                          <p
                                            style={{
                                              textDecoration: "line-through",
                                              fontFamily: "delivery",
                                            }}
                                          >
                                            ₹{item.discount}
                                          </p>
                                        )}
                                        <p
                                          style={{ fontFamily: "delivery" }}
                                          className="p-2"
                                        >
                                          ₹{Math.floor(item.dprice)}
                                        </p>
                                      </>
                                    ) : (
                                      <p style={{ fontFamily: "delivery" }}>
                                        ₹{Math.floor(item.price)}
                                      </p>
                                    )}
                                  </div>
                                  <div className="quantity-container">
                                    {Number(item.qty) > 0 ? (
                                      <div className="add_btn_out">
                                        <button
                                          className="quantity-btn"
                                          onClick={() => {
                                            setIsMinusButtonDisabled(true);
                                            handleDecrement(
                                              category.cat_id,
                                              item.id,
                                              item,
                                              item.itemIndex
                                            );
                                          }}
                                          disabled={isMinusButtonDisabled}
                                        >
                                          -
                                        </button>
                                        <h6>
                                          {loadingItems[itemIndex] ? (
                                            <div className="loading-spinner-container">
                                              <LoadingSpinner />
                                            </div>
                                          ) : (
                                            <div>
                                              <h6 className="bold">
                                                {Number(item.qty)}
                                              </h6>
                                            </div>
                                          )}
                                        </h6>

                                        <button
                                          className="quantity-btn"
                                          onClick={() => {
                                            setIsPlusButtonDisabled(true);
                                            handleCheckOut(
                                              category.cat_id,
                                              item.id,
                                              item,
                                              item.itemIndex
                                            );
                                          }}
                                          id="addtocart"
                                          disabled={isPlusButtonDisabled}
                                        >
                                          +
                                        </button>
                                      </div>
                                    ) : item.status === "1" ? (
                                      <button
                                        className="add_btn"
                                        onClick={() =>
                                          handleIncrement(
                                            category.cat_id,
                                            item.id,
                                            item,
                                            item.itemIndex
                                          )
                                        }
                                        disabled={isAddButtonDisabled}
                                      >
                                        ADD
                                      </button>
                                    ) : (
                                      <button disabled className="btn-sold">
                                        {item.start ? (
                                          <span className="next-available">
                                            Next Available at
                                            <br />
                                            {item.start}
                                          </span>
                                        ) : (
                                          <span className="sold">SOLD</span>
                                        )}
                                      </button>
                                    )}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    position: "absolute",
                                    bottom: "-19px",
                                    right: "10px",
                                    fontSize: "13px",
                                  }}
                                >
                                  {item.ingrecount === 1 && !item.start ? (
                                    <p style={{ color: "#6c757d" }}>
                                      Customizable
                                    </p>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className=" m-2 res-btm-disp">
          <div className=" ">
            {menu.menu_category &&
              menu.menu_category.map((category, index) => (
                <div
                  key={index}
                  className=""
                  style={{
                    display:
                      selectedCategory === index || selectedCategory === null
                        ? "block"
                        : "none",
                  }}
                >
                  <div className="lin-or  position-relative m-3">
                    <span
                      style={{ textAlign: "center", fontFamily: "heading" }}
                    >
                      {category.data.length == 0 ? (
                        <></>
                      ) : (
                        <div>{category.cat_name}</div>
                      )}
                    </span>
                  </div>
                  <div className="">
                    {category.data &&
                      category.data.map((item, itemIndex) => {
                        return (
                          <div
                            className=""
                            key={itemIndex}
                            id={`mobile-item-${item.id}`}
                          >
                            <div className="d-flex flex-row resp-catout mb-3">
                              <div>
                                <div className="">
                                  <div>
                                    <div className="food-image">
                                      <div className="">
                                        <img
                                          src={
                                            item.foodtype === 1 ? veg : nonveg
                                          }
                                          alt="Non-Veg Food"
                                          className="img-veg"
                                        />
                                      </div>
                                      <h4 className="menu-name_m p-1">
                                        {item.menu_name}
                                      </h4>
                                    </div>
                                    <h6 className="description m-2">
                                      {item.description}
                                    </h6>
                                  </div>

                                  {/* <div style={{ height:"30px" }}> */}
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      position: "absolute",
                                      bottom: "-15px",
                                      right: "35px",
                                      fontSize: "11px",
                                    }}
                                  >
                                    {item.ingrecount === 1 && !item.start ? (
                                      <p style={{ color: "#6c757d" }}>
                                        Customizable
                                      </p>
                                    ) : (
                                      <div></div>
                                    )}
                                  </div>
                                  {/* </div> */}
                                </div>
                                <div className="btn-price">
                                  <div className="price-and-button">
                                    {item.dprice ? (
                                      <>
                                        {item.discount > 0 && (
                                          <p
                                            style={{
                                              textDecoration: "line-through",
                                              fontFamily: "delivery",
                                            }}
                                          >
                                            ₹{item.discount}
                                          </p>
                                        )}
                                        <p
                                          style={{ fontFamily: "delivery" }}
                                          className="p-2"
                                        >
                                          ₹{item.dprice}
                                        </p>
                                      </>
                                    ) : (
                                      <p style={{ fontFamily: "delivery" }}>
                                        ₹{item.price}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="position-relative resp-right-hei">
                                <div className="resp-out-img">
                                  <img
                                    src={
                                      item.menu_image
                                        ? `${menu_item_image}${item.menu_image}`
                                        : branch
                                    }
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = branch;
                                    }}
                                    style={{
                                      backgroundColor:
                                        item.status === "1"
                                          ? ""
                                          : "dark-background",
                                      filter:
                                        item.status === "1"
                                          ? "none"
                                          : "grayscale(100%)", // Apply filter for black and white
                                    }}
                                    width={100}
                                    className={` ${item.status === "1"
                                      ? ""
                                      : "dark-background"
                                      }`}
                                    alt={item.menu_name}
                                  />
                                </div>
                                <div className="quantity-container resp-quan-cont">
                                  {Number(item.qty) > 0 ? (
                                    <div className="add_btn_out resp-btn-out-plus">
                                      <button
                                        className="quantity-btn"
                                        onClick={() => {
                                          setIsMinusButtonDisabled(true);
                                          handleDecrement(
                                            category.cat_id,
                                            item.id,
                                            item,
                                            item.itemIndex
                                          );
                                        }}
                                        disabled={isMinusButtonDisabled}
                                      >
                                        -
                                      </button>
                                      <h6>
                                        {loadingItems[itemIndex] ? (
                                          <div className="loading-spinner-container">
                                            <LoadingSpinner />
                                          </div>
                                        ) : (
                                          <div>
                                            <h6 className="bold">
                                              {Number(item.qty)}
                                            </h6>
                                          </div>
                                        )}
                                      </h6>
                                      {/* <h6>
                                    {loadingItems[itemIndex] && (
                                      <div className="loading-spinner-container">
                                        <LoadingSpinner/>
                                      </div>
                                    )}
                                    {!loadingItems[itemIndex] && <div>{Number(item.qty)}</div>}
                                  </h6> */}

                                      {/* <h4>{item.qty}</h4> */}
                                      <button
                                        className="quantity-btn"
                                        onClick={() => {
                                          setIsPlusButtonDisabled(true);
                                          handleCheckOut(
                                            category.cat_id,
                                            item.id,
                                            item,
                                            item.itemIndex
                                          );
                                        }}
                                        id="addtocart"
                                        disabled={isPlusButtonDisabled}
                                      // onClick={kl}
                                      >
                                        +
                                      </button>
                                    </div>
                                  ) : item.status === "1" ? (
                                    <button
                                      className="add_btn"
                                      onClick={() =>
                                        handleIncrement(
                                          category.cat_id,
                                          item.id,
                                          item,
                                          item.itemIndex
                                        )
                                      }
                                      disabled={isAddButtonDisabled}
                                    >
                                      ADD
                                    </button>
                                  ) : (
                                    <button disabled className="btn-sold">
                                      {item.start ? (
                                        <span className="next-available">
                                          Next Available at {item.start}
                                        </span>
                                      ) : (
                                        <span className="sold">SOLD</span>
                                      )}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <Modal
          show={showmodalBtn}
          onHide={() => {
            setIsPlusButtonDisabled(false);
            setShowmodalBtn(false);
          }}
          className="right-aligned-modal"
          animation={false}
        >
          <Modal.Body className="repeat-modal-body">
            <h5>Repeat last used customization?</h5>
            <div className="two-btn">
              <button
                className="will-choose"
                onClick={() => {
                  setIsPlusButtonDisabled(false);
                  handleNewItem(chooseItem);
                }}
              >
                I'll choose
              </button>
              <button
                className="repeat "
                onClick={() => {
                  setIsPlusButtonDisabled(false);
                  handleRepeatIncrement();
                }}
              >
                Repeat
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* { cart customezedd item} */}
        <Modal
          show={editaddonModal}
          onHide={() => setEditAddonModal(false)}
          animation={false}
        >
          <Modal.Body>
            <div
              style={{
                position: "relative",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
                textAlign: "center",
              }}
            >
              <h4 style={{ margin: 0 }}>{customName}</h4>
              <button
                className="titleCloseBtn"
                style={{
                  position: "absolute",
                  right: 0,
                  top: "36%",
                  transform: "translateY(-50%)",
                }}
                onClick={() => setEditAddonModal(false)}
              >
                X
              </button>
            </div>
            {addondata.ingre &&
              addondata.ingre.map((ingredient, index) => (
                <div key={index}>
                  <input
                    type="checkbox"
                    name="ingredients"
                    id={`ingredient${index}`}
                    checked={
                      addondata.selectedIngredients &&
                      addondata.selectedIngredients.includes(ingredient.name)
                    }
                    onChange={() => handleIngredientChange(ingredient.name)}
                  />
                  <label htmlFor={`ingredient${index}`}>
                    {ingredient.name}
                  </label>
                </div>
              ))}
            {/* <h2>Variant</h2> */}
            {addondata.variant &&
              addondata.variant.map((variant, index) => (
                <div key={index}>
                  <div style={{display:"flex"}}>
                    <h5
                      style={{
                        display:
                          variant.heading === "Quantity" ? "none" : "block",
                      }}
                    >
                      {variant.heading}
                    </h5>
                    <h5 style={{ flex: 1, textAlign: "right" }}>Price</h5>
                    <div>
                  
                    </div>
                  </div>


                  {variant.data &&
                    variant.data.map((item, itemIndex) => (
                      <div key={itemIndex} className="variant-item w-100">
                        <input
                          type="radio"
                          name={`variant${index}`}
                          id={`variant${index}_${itemIndex}`}
                          className="custom-radio"
                          checked={
                            addondata.selectedVariants &&
                            addondata.selectedVariants.some(
                              (selectedVariant) =>
                                selectedVariant &&
                                selectedVariant.heading === variant.heading &&
                                selectedVariant.selectedVariant ===
                                item.variant_name
                            )
                          }
                          onChange={() =>
                            handleVariantChange(
                              variant.heading,
                              item.id,
                              item.variant_name,
                              item.price
                            )
                          }
                        />
                        <div style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center"
                        }}>



                          <div style={{
                            display: "flex"
                          }}>
                            <label
                              htmlFor={`variant${index}_${itemIndex}`}
                              style={{ cursor: "pointer", }}
                            ></label>
                            <div>
                              <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}>{item.variant_name}</div>

                            </div>
                            <div>

                            </div>
                          </div>

                          {/* {item.price} */}
                          <div
                            style={{
                              marginLeft: "auto",
                              textAlign: "right",
                            }}
                          >
                            {item.price === 0 ? "Free" : `${item.price} Rs`}
                          </div>


                        </div>
                      </div>
                    ))}
                </div>
              ))}

            <div className="d-flex justify-content-center">
              <button
                className="btn-sub "
                onClick={() =>
                  handleUpdateCartAddon(
                    selectedMeat.index,
                    selectedMeat.categoryId,
                    selectedMeat.itemId,
                    addondata.selectedIngredients,
                    addondata.selectedVariants
                  )
                }
              >
                Update
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* END OF ADDONS */}
        <Modal
          show={addonModal}
          onHide={() => {
            setAddonModal(false);
            setAddButtonDisabled(false);
          }}
          dialogClassName="right-addons-modal"
          animation={false}
        >
          <Modal.Body>
            <div
              style={{
                position: "relative",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
                textAlign: "center",
              }}
            >
              <h4 style={{ margin: 0 }}>{customName}</h4>
              <button
                className="titleCloseBtn"
                style={{
                  position: "absolute",
                  right: 0,
                  top: "36%",
                  transform: "translateY(-50%)",
                }}
                onClick={() => {
                  setAddButtonDisabled(false)
                  setAddonModal(false)
                }}
              >
                X
              </button>
            </div>

            {/* <h2>Ingredients</h2> */}

            {addondata.ingre && (
              <ul>
                {addondata.ingre.map((ingredient, index) => (
                  <li key={index}>
                    <input
                      type="checkbox"
                      name="ingredients"
                      id={`ingredient${index}`}
                    />
                    <label htmlFor={`ingredient${index}`}>
                      {ingredient.name}
                    </label>
                  </li>
                ))}
              </ul>
            )}

            {/* <h2>Variant</h2> */}
            {/* {addondata.variant &&
                addondata.variant.map((variant, index) => (
                  <div key={index}>
                    <h3
                      style={{
                        display:
                          variant.heading === "Quantity" ? "none" : "block",
                      }}
                    >
                      {variant.heading}
                    </h3>
                    {variant.data && (
                      <ul className="list_item m-1">
                        {variant.data.map((item, itemIndex) => (
                          <li key={itemIndex} className="m-1">
                            <input
                              type="radio"
                              name={`variant${index}`}
                              id={`variant${index}_${itemIndex}`}
                              defaultChecked={item.selection === "1"}
                            />
                           
                            <label htmlFor={`variant${index}_${itemIndex}`}>
                              <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {item.variant_name}
                                <span style={{ marginLeft: 'auto', textAlign: 'left' }}>
                                  {item.price === 0 ? 'Free' : `Charge ${item.price} Rs`}
                                </span>
                              </span>
                            </label>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              {addondata.variant &&
                addondata.variant.map((variant, index) => (
                  <div key={index}>
                    <h3
                      style={{
                        display:
                          variant.heading === "Quantity" ? "none" : "block",
                      }}
                    >
                      price
                    </h3>
                    {variant.data && (
                      <ul className="list_item m-1">
                        {variant.data.map((item, itemIndex) => (
                          <li key={itemIndex} className="m-1">
                            <input
                              type="radio"
                              name={`variant${index}`}
                              id={`variant${index}_${itemIndex}`}
                              defaultChecked={item.selection === "1"}
                            />

                            <label htmlFor={`variant${index}_${itemIndex}`}>
                              <span style={{ display: 'flex', justifyContent: 'space-between' }}>

                                <span style={{ marginLeft: 'auto', textAlign: 'left' }}>
                                  {item.price === 0 ? 'Free' : `Charge ${item.price} Rs`}
                                </span>
                              </span>
                            </label>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))} */}
            {addondata.variant &&
              addondata.variant.map((variant, index) => (
                <div key={index}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #ddd",
                      paddingBottom: "10px",
                    }}
                  >
                    <h5 style={{ flex: 1 }}>{variant.heading}</h5>
                    <h5 style={{ flex: 1, textAlign: "right" }}>Price</h5>
                  </div>
                  {variant.data && (
                    <ul className="list_item m-1">
                      {variant.data.map((item, itemIndex) => (
                        <li
                          key={itemIndex}
                          className="m-1"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <input
                            type="radio"
                            name={`variant${index}`}
                            id={`variant${index}_${itemIndex}`}
                            defaultChecked={item.selection === "1"}
                          />
                          <label
                            htmlFor={`variant${index}_${itemIndex}`}
                            style={{ flexGrow: 1 }}
                          >
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {item.variant_name}
                              <span
                                style={{
                                  marginLeft: "auto",
                                  textAlign: "right",
                                }}
                              >
                                {item.price === 0 ? "Free" : `${item.price} Rs`}
                              </span>
                            </span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}

            <div className="add_direction">
              <button
                onClick={() => {
                  setAddButtonDisabled(false);
                  handleAddon();
                }}
                className="Add_1"
              >
                Add
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* CART MODAL */}

        {/* order success */}
        <div>
          <Modal show={modalDelivery} onHide={() => setModalDelivery(false)}>
            <Modal.Body>
              <h1
                className="d-flex justify-content-center"
                style={{
                  backgroundImage:
                    "linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Order Success...!
              </h1>

              <Lottie
                height={200}
                width={200}
                options={{
                  loop: true,
                  animationData: require("../../Assests/order-success.json"),
                  autoplay: true,
                }}
              />
              <div>
                <div className="d-flex justify-content-center">
                  <div>
                    <button
                      className="cont-shop"
                      onClick={() => setModalDelivery(false)}
                    >
                      CONTINUE SHOPPING
                    </button>
                  </div>
                </div>

                <p className="d-flex justify-content-end">
                  <a onClick={() => navigate("/orders")} className="order-link">
                    Okay, Go to Order <FontAwesomeIcon icon={faArrowRight} />
                  </a>
                </p>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          
          <Modal.Body>
          <div className="select-t">
  <div className="header-t">
    <button className="button-t" onClick={() => setShowModal(false)}>x</button>
  </div>
  <div className="message-t">
    <h4 className="address-select-message">
      Please select an address to proceed with the order
    </h4>
  </div>
</div>



          </Modal.Body >

          {/* <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer> */}
        </Modal>
      </div>
    </div>
  );
}

export default Mainhome;
