import React, { useEffect, useRef, useState } from "react";
import "./Login.css";
import q2h from "../../Assests/q2h.png";
import Footer from "../Footer/Footer";
import gps from "../../Assests/gps.png";
import { useNavigate } from "react-router-dom";
import chkcrp from "../../Assests/chicken crp.jpg";
import Api from "../../Global/Api";
import { Token } from "../../Global/Firebase";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { MdEdit } from "react-icons/md";
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
function Login() {
  const [address, setAddress] = useState(null);
  const handleCloose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [showSignup, setShowSignup] = useState(true);
  const [otp, setOtp] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [otpNew, setOtpNew] = useState(["", "", "", ""]);
  const [newOtp, setNewOtp] = useState("");
  const [error, setError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [otpView, setOtpView] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [issignUp, setSignUp] = useState(false);
  const [otpGenerated, setOtpGenerated] = useState(false);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [enabledSign, setEnabledSign] = useState(false);
  const [timeLeft, setTimeLeft] = useState(59);
  const [isVerifyButton, setVerifyButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const [currentLocation, setCurrentLocation] = useState(null);
  const [loginAddress, setLoginAddress] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isWideScreen, setIsWideScreen] = useState(true);
  const [enable, setEnable] = useState(false);
  console.log(latitude);
  const [longitude, setLongitude] = useState(null);
  console.log(longitude);
  const inputRef = useRef();
  const CallApi = new Api();
  const navigate = useNavigate();

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(showPosition);
    } else {

      Swal.fire({
        icon: 'error',
        title: 'Invalid Geolocation',
        text: 'Geolocation is not supported by this browser',
      });
    }
  }
  useEffect(() => {
    setShow(true);
  }, []);
  useEffect(() => {
    if (timeLeft === 0) {
      // setInputDisabled(false);
      // setEnabled(false);
      setButtonDisabled(true);

      // setEnabledSign(false);

    }
  }, [timeLeft]);

  const getLocations = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLatitude(latitude);
          setLongitude(longitude);
          console.log(latitude, longitude);
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth <= 330);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  ///AIzaSyAMivURP6jd9dxnHmEVoUh-5oo_fEbOTGM
  const getAddressFromGoogle = () => {
    console.log("location called");

    //AIzaSyBI128TbOGiD60MgIoxXXO_KtJN_PP2a8U
    const Api = `https://maps.googleapis.com/maps/api/geocode/json?address=${latitude},${longitude}&key=`;
    fetch(Api)
      .then((response) => response.json())
      .then((responseJson) => {
        const addr = JSON.stringify(
          responseJson.results[2].formatted_address
        ).replace(/"/g, "");
        console.log({ addr: addr });
        setLoginAddress(addr);
        localStorage.setItem("lat", latitude);
        localStorage.setItem("long", longitude);
      })

      .catch((error) => {
        console.error("Error fetching address:", error);
      });
  };
  const handleCancelOtp = () => {
    setInputDisabled(false);
    setEnabled(false);
    setButtonDisabled(false);
    setOtp(false);
    setOtpGenerated(false);

    setEnabledSign(false);
  }
  const handleSignup = () => {
    setEnabledSign(true);
    setInputDisabled(true);

    if (name && mobileNumber && email) {
      if (mobileNumber.length !== 10) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Mobile Number',
          text: 'Please Enter a Valid Mobile Number!',
        });
        handleCancelOtp();  // Clear OTP or handle as necessary
      } else if (!email.includes("@") || !email.includes(".")) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Email Address',
          text: 'Please enter a valid email address!',
        });
        handleCancelOtp();  // Clear OTP or handle as necessary
      } else {
        if (!error && !emailError) {
          try {
            // handleOtpCounter();
            setShowSignup(false);
            
            const hash = "";
            const token = localStorage.getItem("token");
            const tk = encodeURIComponent(hash);
            const tk1 = encodeURIComponent(token);
            const os = "web";
            CallApi.getSignUp({
              maild: email,
              mobile_no: mobileNumber,
              type: os,
              token: tk1,
              hash: tk,
            }).then((res) => {
              console.log(res)
              if (res.data.success === "1") {
                localStorage.setItem("isLoggedIn", true);
                setVerifyButton(true);
                 setOtpGenerated(true);
                 setOtp(true);
                setOtpView(true);

              }else {
                 alert(res.data.error)
                 setShowSignupForm(false);
                 setShowLoginForm(true);
                 setInputDisabled(false);
                 setMobileNumber("");
                 setIsLogin(true);
                 setSignUp(false);
                 setEnabledSign(false);
              }
            });
          } catch (err) {
            setError(err);
          }
        } else {
          
          Swal.fire({
            icon: 'error',
            title: 'Invalid error',
            text: 'please clear error',
          });
          setEnabledSign(false);

        }

      }

    } else {
      Swal.fire({
        icon: 'error',
        title: 'Missing Fields',
        text: 'Please Enter All Details',
      });
      setEnabledSign(false);
      setInputDisabled(false);

    }
    setTimeLeft(59);
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(inputValue)) {
      setName(inputValue);
    }
  };
  const handleBack = () => {

    window.location.reload()

  }
  const handleLogin = () => {

    const regex = /^\d{10}$/;
    const isValidMobileNumber = regex.test(mobileNumber);

    setEnabled(true);

    if (!isValidMobileNumber) {
      setErrorMessage("Please enter a valid 10-digit mobile number");
      setEnabled(false);
      return;
    }

    try {
      // handleOtpCounter();
     

      const hash = "";
      const token = localStorage.getItem("token");
      const tk = encodeURIComponent(hash);
      const tk1 = encodeURIComponent(token);
      const os = "web";
      CallApi.getLogin({
        mobile_no: mobileNumber,
        type: os,
        token: tk1,
        hash: tk,
      }).then((res) => {
        console.log('object1', res)
        if (res.data.success === "1") {
          setVerifyButton(true);
          setOtpGenerated(true);
          setOtp(true);
          console.log(res.data.mail_id);
          localStorage.setItem("mail_id", res.data.mail_id);
          setEmail(res.data.mail_id);
          setOtpView(true);

        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'This Mobile Number Does Not Exit!',
            text: 'Sign Up to Continue!',
          });

          setOtpGenerated(false);
          setOtp(false);
        }
      });
    } catch (err) {
      setError(err);
    }

    setInputDisabled(true);
    setButtonDisabled(true);
    setTimeLeft(59);

  };

  const handleResendOTP = () => {
    setResendEnabled(false);
    setOtpGenerated(true);
    setOtp(true);
    setTimeLeft(59);
    const hash = ""; // Not sure what this is intended for
    const token = localStorage.getItem("token");
    const tk = encodeURIComponent(hash);
    const tk1 = encodeURIComponent(token);
    const os = "web";
    CallApi.getLogin({
      mobile_no: mobileNumber,
      type: os,
      token: tk1,
      hash: tk,
    }).then((res) => {
      if (res.data.success === "1") {
        console.log(res.data.mail_id);
        localStorage.setItem("mail_id", res.data.mail_id);
        setEmail(res.data.mail_id);
        setOtpView(true);
      }
    });
    setInputDisabled(true);
  }; // Removed extra curly brace here

  //This function for OTP SET A STATE //////////////////////////////////
  const otpInputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const handleOTPInputChange = (e, index) => {
    const value = e.target.value;
    setOtpNew((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      if (/^[0-9]*$/.test(value) && value !== "" && index < 3) {
        otpInputRefs[index + 1].current.focus();
      }
      const allInputsFilled = newOtp.every((input) => /^[0-9]*$/.test(input));
      setVerifyButton(allInputsFilled);
      const otpString = newOtp.join("");
      setNewOtp(otpString);
      return newOtp;
    });

    if (/^[0-9]*$/.test(value) && value !== "" && index < 3) {
      otpInputRefs[index + 1].current.focus();
    }

    if (otpInputRefs.every((ref) => ref.current.value)) {
      setVerifyButton(true);
      setEnable(false);
    } else {
      setVerifyButton(true)
      setEnable(true);
    }
  };
  //This Function for verify otp //////////////////////////////////
  const handleOtpVerify = () => {
    console.log("called");
    if (newOtp) {
      try {
        let dataToSend = { mobileNumber: mobileNumber };
        let formBody = [];
        for (let key in dataToSend) {
          let encodedKey = encodeURIComponent(key);
          let encodedValue = encodeURIComponent(dataToSend[key]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        CallApi.getOtp({
          mobile_no: mobileNumber,
          otp: newOtp,
        }).then((res) => {
          if (res.data.success === "1") {
            console.log(res.data);
            localStorage.setItem("user_id", res.data.userid);
            localStorage.setItem("mobile_no", mobileNumber);
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("mail_id", email);
            localStorage.setItem("name", name);

            navigate("/BranchPage");
          } else if (res.data.success === "0") {
            Swal.fire({
              icon: 'error',
              title: 'Invalid OTP',
              text: 'Please enter a valid OTP',
            });

          }
        });
      } catch (err) {
        setError(err);
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Invalid OTP',
        text: 'Please enter OTP',
      });

    }
  };

  function showPosition(position) {
    console.log(position.coords.latitude, position.coords.longitude);
  }
  const handleLoginClick = () => {
    setShowLoginForm(true);
    setShowSignupForm(false); // Hide signup form
    setIsLogin(true);
  };

  const handleSignupClick = () => {
    setShowSignupForm(true);
    setShowLoginForm(false);
    setSignUp(true);
    setInputDisabled(false);
    setEnabledSign(false);
    setButtonDisabled(false);
    setName("");
    setEmail("");

  };
  const handleVerifyOTP = () => {
    // Implement the verification logic here
    // For example, you can compare the entered OTP with the generated OTP
    // and take appropriate actions when the OTP is verified.
  };
  const handleBackToMain = () => {
    setShowLoginForm(false);
    setShowSignupForm(false);
  };

  const handleInputChange = (e) => {
    console.log('noblurrrr')
    const { value } = e.target;
    // Allow only numbers
    const numericValue = value.replace(/\D/g, "");
    setMobileNumber(numericValue);
    const pattern = /^\d{10}$/; // Adjusted pattern to match exactly 10 digits
    if (!pattern.test(mobileNumber)) {
      setError("");
    }
  };

  // const handleNOBlur = () => {

  //   const pattern = /^\d{10}$/; // Adjusted pattern to match exactly 10 digits
  //   if (!pattern.test(mobileNumber)) {
  //     setError("Please enter a valid 10-digit mobile number");
  //   } else {

  //     setError("");
  //   }
  // };

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    const pattern = /^[a-zA-Z0-9._%+-]+@gmail\.com$/; // Regex pattern for gmail.com emails
    if (pattern.test(inputValue)) {
      setEmailError(""); // Clear any previous error if the entered email is valid
    }
  };

  // const handleEmailBlur = () => {
  //   const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  //   // Regex pattern for gmail.com emails
  //   if (!pattern.test(email)) {
  //     setEmailError("Please enter a valid Gmail address");
  //   } else {
  //     setEmailError(""); // Clear any previous error
  //   }
  // };

  const handleMobileNumberChange = (e) => {
    const input = e.target.value;

    if (/^\d{0,10}$/.test(input)) {
      setMobileNumber(input);
      setErrorMessage("");
    }
  };

  const handleMobileNumberSubmit = () => {
    // Perform login logic with the mobile number
    console.log("Logging in with mobile number:", mobileNumber);
    setOtp(true);
    setShowLoginForm(false);
  };

  const handleSignupSubmit = () => {
    // Perform signup logic with the entered details
    setOtp(true);
    setShowSignupForm(false);
    setShowLoginForm(false);
  };
  const handlePlaceChanged = () => {
    const [places] = inputRef.current.getPlaces();
    if (places) {
      setLoginAddress(places.formatted_address);
      localStorage.setItem("login_address", places.formatted_address);
    }
  };
  const libraries = ["places"];
  useEffect(() => {
    Token();
  }, []);

  // useEffect(() => {
  //   if (otpGenerated) {
  //     const timer = setInterval(() => {
  //       if (timeLeft > 0) {
  //         setTimeLeft(timeLeft - 1);
  //       } else {
  //         setResendEnabled(true);
  //         clearInterval(timer);
  //       }
  //     }, 1000);

  //     return () => clearInterval(timer);
  //   }
  // }, [otpGenerated, timeLeft]);

  useEffect(() => {
    getLocations();
  });

  useEffect(() => {
    if (otpGenerated) {
      const timer = setInterval(() => {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1);
        } else {
          setResendEnabled(true);
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [otpGenerated, timeLeft]);

  return (
    <div className="">
      <div>
        <div className="container con-out-bh">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-6 position-relative">
              <div>
                <div className="or-bx"></div>
              </div>
              <div className="q2h_logo">
                <img
                  src={q2h}
                  alt="img"
                  onClick={() => window.location.reload()}
                  className="logo_1"
                />
              </div>
              <div className="content">
                <div className="">
                  <div className="content_txt d-flex flex-column animated-text">
                    {/* <span className="span_txt">Hello!</span> */}
                    <span className="welcome-text d-flex flex-column">
                      1st Mobile App to Deliver Fresh Meat Within 60 Minutes.
                      {/* <span className="welcome-text">Order !</span> */}
                    </span>
                  </div>
                </div>

                {showLoginForm ? (
                  <div className="signup_align">
                    <input
                      type="text"
                      placeholder="Enter your mobile number"
                      value={mobileNumber}
                      className="input_des_3"
                      onChange={handleMobileNumberChange}
                      disabled={inputDisabled}
                      onKeyDown={(e) => {
                        // Allow only numbers to be typed
                        const pattern = /[0-9]/;
                        const charCode = e.which ? e.which : e.keyCode;
                        const charTyped = String.fromCharCode(charCode);
                        // Allow input from Num Lock number keys (keycodes 96 to 105)
                        if (
                          !pattern.test(charTyped) &&
                          charCode !== 8 && // Backspace
                          charCode !== 9 &&
                          (charCode < 96 || charCode > 105) // Num Lock number keys
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />

                    {errorMessage && (
                      <p style={{ color: "red" }}>{errorMessage}</p>
                    )}
                    <button
                      className="btnlogin"
                      style={{ backgroundColor: enabled ? "#dddd" : "#f48407" }}
                      disabled={buttonDisabled}
                      onClick={handleLogin}
                    >
                      <div className="center">
                        <div className="radius">
                          <span>Log in</span>
                          <svg
                            className="icon-arrow after"
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 403.8 200.3"
                            style={{
                              enableBackground: "new 0 0 403.8 200.3",
                            }}
                            xmlSpace="preserve"
                          >
                            <style>{`.st0 { fill: #fff; }`}</style>
                            <g>
                              <g>
                                <path
                                  className="st0"
                                  d="M25.2,114.3h317.3L290,166.6c-5.9,5.8-5.9,15.3-0.1,21.2c5.8,5.9,15.3,5.9,21.2,0.1l78.3-77.9c0,0,0,0,0,0 c5.9-5.8,5.9-15.4,0-21.2c0,0,0,0,0,0l-78.3-77.9c-5.9-5.8-15.4-5.8-21.2,0.1c-5.8,5.9-5.8,15.4,0.1,21.2l52.6,52.3H25.2 c-8.3,0-15,6.7-15,15C10.2,107.6,16.9,114.3,25.2,114.3z"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </button>
                    {/* <button onClick={handleBackToMain}>Back to Main</button> */}
                  </div>
                ) : showSignupForm ? (
                  <div className="signup_align">
                    <input
                      type="text"
                      placeholder="Enter your name"
                      value={name}
                      className="input_des"
                      maxLength={20}
                      disabled={inputDisabled}
                      onChange={handleNameChange}
                    />

                    <input
                      type="tel"
                      placeholder="Enter your mobile number"
                      value={mobileNumber}
                      className="input_des"
                      onChange={handleInputChange}
                      // onBlur={()=>handleNOBlur()}
                      disabled={inputDisabled}
                      maxLength={10}
                      onKeyDown={(e) => {
                        // Allow only numbers to be typed
                        const pattern = /[0-9]/;
                        const charCode = e.which ? e.which : e.keyCode;
                        const charTyped = String.fromCharCode(charCode);
                        // Allow input from Num Lock number keys (keycodes 96 to 105)
                        if (
                          !pattern.test(charTyped) &&
                          charCode !== 8 && // Backspace
                          charCode !== 9 &&
                          (charCode < 96 || charCode > 105) // Num Lock number keys
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {error && <p style={{ color: "red" }}>{error}</p>}

                    <input
                      type="email"
                      placeholder="Enter your email"
                      required
                      className="input_des"
                      value={email}
                      disabled={inputDisabled}
                      onChange={handleEmailChange}
                    // onBlur={handleEmailBlur}
                    />

                    {emailError && <p style={{ color: "red" }}>{emailError}</p>}

                    <button
                      className="btnlogin"
                      style={{
                        backgroundColor: enabledSign ? "#dddd" : "#f48407",
                      }}
                      disabled={buttonDisabled}
                      onClick={() => 
                        // {
                        // if (!showSignup) {
                        //   console.log("please wait lo click button")
                        // }else {
                          handleSignup()
                        // }
                        // }
                      }
                    >
                      <div className="center">
                        <div className="radius">
                          <span className="siggg">Sign Up</span>
                          <svg
                            className="icon-arrow after"
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 403.8 200.3"
                          
                            xmlSpace="preserve"
                          >
                           
                            <g>
                              <g>
                              <path
                                      d="M25.2,114.3h317.3L290,166.6c-5.9,5.8-5.9,15.3-0.1,21.2c5.8,5.9,15.3,5.9,21.2,0.1l78.3-77.9c0,0,0,0,0,0
           c5.9-5.8,5.9-15.4,0-21.2c0,0,0,0,0,0l-78.3-77.9c-5.9-5.8-15.4-5.8-21.2,0.1c-5.8,5.9-5.8,15.4,0.1,21.2l52.6,52.3H25.2
           c-8.3,0-15,6.7-15,15C10.2,107.6,16.9,114.3,25.2,114.3z"
                                      fill="#fff"
                                    />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </button>
                    <button className="btnlogin" onClick={handleBack}>Back</button>
                    {/* <button onClick={handleBackToMain}>Back to Main</button> */}
                  </div>
                ) : null}

              </div>

              {show && (
                <div
                  className="modal fade show"
                  id="exampleModalCenter"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                  style={{
                    display: "block",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  }} // Ensure the modal backdrop is visible
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header d-flex justify-content-end">
                        <button
                          type="button"
                          aria-label="Close"
                          onClick={handleCloose}
                          className="titleCloseBtn ms-auto"
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </button>
                      </div>

                      <div className="modal-body d-flex justify-content-center">
                        <div className="bug">
                          <p className="dw-con_1 text-center">
                            <span className="better">
                              For Better Experience{" "}
                            </span>
                            <span className="better">
                              Download The Q2H App Now
                            </span>
                          </p>

                            <ul className="d-flex justify-content-center gpap">
                              <li className="">
                                <a
                                  href="https://play.google.com/store/apps/details?id=com.falconsquare.q2hmeats"
                                  target="_blank"
                                >
                                  <div class="download android ">
                                    <i class="fa fa fa-play store fa-3x"></i>
                                    <div>
                                      <span class="df">Download from</span>
                                      <span class="dfn">Google Play</span>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <li className="">
                                <a
                                  href="https://apps.apple.com/in/app/q2h-quick-2-home-meat-delivery/id1662482699"
                                  target="_blank"
                                >
                                  <div class="download apple">
                                    <i class="fa fa fa-apple fa-3x"></i>
                                    <span class="df">Download from</span>
                                    <span class="dfn">App Store</span>
                                  </div>
                                </a>
                              </li>
                            </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}


              {/* <Modal show={show}
                onHide={handleCloose}
                dialogClassName="right-enter-modal"
                animation={false}>
                <Modal.Header closeButton>
                  <Modal.Title>

                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center align-items-center">
                  <div className="bug">
                    <p className="dw-con_1">
                      <span className="better">For Better Experience </span>{" "}
                      <span className="better">Download The Q2H App Now</span>
                    </p>
                    <ul className="d-flex  gpap">
                      <li className="">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.falconsquare.q2hmeats"
                          target="_blank"
                        >
                          <div class="download android">
                            <i class="fa fa fa-play store fa-3x"></i>
                            <div>
                              <span class="df">Download from</span>
                              <span class="dfn">Google Play</span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li className="">
                        <a href="https://apps.apple.com/in/app/q2h-quick-2-home-meat-delivery/id1662482699" target="_blank">
                          <div class="download apple">
                            <i class="fa fa fa-apple fa-3x"></i>
                            <span class="df">Download from</span>
                            <span class="dfn">App Store</span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </Modal.Body>
              </Modal> */}
              {otp && (
                <div className="modal_11">
                  <div className="shadow_11">
                    <div className="modal_content_11">
                      <div className="otp-input p-2">
                        <input
                          type="tel"
                          placeholder="0"
                          maxLength={1}
                          className="otp-box"
                          required
                          ref={otpInputRefs[0]}
                          onChange={(e) => handleOTPInputChange(e, 0)}
                          autoFocus
                          onKeyDown={(e) => {
                            const pattern = /[0-9]/;
                            const charCode = e.which ? e.which : e.keyCode;
                            const charTyped = String.fromCharCode(charCode);

                            // Allow numeric keys (top row and numeric keypad)
                            if (
                              (charCode >= 48 && charCode <= 57) ||
                              (charCode >= 96 && charCode <= 105)
                            ) {
                              return;
                            }

                            // Allow Backspace
                            if (
                              e.key === "Backspace" &&
                              e.target.value === ""
                            ) {
                              const currentIndex = otpInputRefs.findIndex(
                                (ref) => ref.current === e.target
                              );
                              if (currentIndex > 0) {
                                e.preventDefault();
                                otpInputRefs[currentIndex - 1].current.focus();
                                otpInputRefs[currentIndex - 1].current.value =
                                  "";
                              }
                            } else {
                              e.preventDefault();
                            }
                          }}
                        />
                        <input
                          type="tel"
                          placeholder="0"
                          maxLength={1}
                          className="otp-box"
                          required
                          ref={otpInputRefs[1]}
                          onChange={(e) => handleOTPInputChange(e, 1)}
                          onKeyDown={(e) => {
                            const pattern = /[0-9]/;
                            const charCode = e.which ? e.which : e.keyCode;
                            const charTyped = String.fromCharCode(charCode);

                            // Allow numeric keys (top row and numeric keypad)
                            if (
                              (charCode >= 48 && charCode <= 57) ||
                              (charCode >= 96 && charCode <= 105)
                            ) {
                              return;
                            }

                            // Allow Backspace
                            if (
                              e.key === "Backspace" &&
                              e.target.value === ""
                            ) {
                              const currentIndex = otpInputRefs.findIndex(
                                (ref) => ref.current === e.target
                              );
                              if (currentIndex > 0) {
                                e.preventDefault();
                                otpInputRefs[currentIndex - 1].current.focus();
                                otpInputRefs[currentIndex - 1].current.value =
                                  "";
                              }
                            } else {
                              e.preventDefault();
                            }
                          }}
                        />
                        <input
                          type="tel"
                          placeholder="0"
                          maxLength={1}
                          className="otp-box"
                          required
                          ref={otpInputRefs[2]}
                          onChange={(e) => handleOTPInputChange(e, 2)}
                          onKeyDown={(e) => {
                            const pattern = /[0-9]/;
                            const charCode = e.which ? e.which : e.keyCode;
                            const charTyped = String.fromCharCode(charCode);

                            // Allow numeric keys (top row and numeric keypad)
                            if (
                              (charCode >= 48 && charCode <= 57) ||
                              (charCode >= 96 && charCode <= 105)
                            ) {
                              return;
                            }

                            // Allow Backspace
                            if (
                              e.key === "Backspace" &&
                              e.target.value === ""
                            ) {
                              const currentIndex = otpInputRefs.findIndex(
                                (ref) => ref.current === e.target
                              );
                              if (currentIndex > 0) {
                                e.preventDefault();
                                otpInputRefs[currentIndex - 1].current.focus();
                                otpInputRefs[currentIndex - 1].current.value =
                                  "";
                              }
                            } else {
                              e.preventDefault();
                            }
                          }}
                        />

                        <input
                          type="tel"
                          placeholder="0"
                          maxLength={1}
                          required
                          className="otp-box"
                          ref={otpInputRefs[3]}
                          onChange={(e) => handleOTPInputChange(e, 3)}
                          onKeyDown={(e) => {
                            // Allow only numbers to be typed
                            const pattern = /[0-9]/;
                            const charCode = e.which ? e.which : e.keyCode;
                            const charTyped = String.fromCharCode(charCode);
                            if (
                              (charCode >= 48 && charCode <= 57) ||
                              (charCode >= 96 && charCode <= 105)
                            ) {
                              return;
                            }
                            // Allow only numbers and backspace
                            if (!pattern.test(charTyped) && charCode !== 8) {
                              e.preventDefault();
                            }

                            // Handle backspace to delete the current value and move back to the previous input field
                            if (charCode === 8 && e.target.value === "") {
                              // Get the index of the current input
                              const currentIndex = otpInputRefs.findIndex(
                                (ref) => ref.current === e.target
                              );
                              if (currentIndex > 0) {
                                // Move focus to the previous input
                                otpInputRefs[currentIndex - 1].current.focus();
                                // Clear the value of the previous input
                                otpInputRefs[currentIndex - 1].current.value =
                                  "";
                              }
                            }
                          }}
                        />
                      </div>
                      <p className="otpcolor">
                        {otpGenerated
                          ? ` ${timeLeft} seconds`
                          : "OTP not generated"}
                      </p>
                      <p className="edit" onClick={handleCancelOtp}>Change Number</p>
                      {otpGenerated && isVerifyButton && timeLeft ? (
                        <button className={`btn_11 ${enable ? "disabled" : ""
                          }`} onClick={handleOtpVerify} disabled={enable}>
                          Verify OTP
                        </button>
                      ) : (
                        <div>
                          {/* <button className="btn_12" >Submit</button> */}
                          <button
                            className={`btn_11 ${resendEnabled ? "" : "disabled"
                              }`}
                            onClick={handleResendOTP}
                            disabled={!resendEnabled}
                          >
                            Resend OTP
                          </button>
                        </div>
                      )}
                      {/* <button
                        className="btn_11"
                        onClick={ handleOtpVerify}
                      >
                        Verify OTP
                      </button> */}
                    </div>
                  </div>
                </div>
              )}
              <div className="login_main">
                {!issignUp ? (
                  <div className="">
                    <div className="login_24">
                      <button
                        className="btn_login_1"
                        onClick={() => handleSignupClick()}
                      >
                        <div className="center">
                          <div className="radius">
                            <span className="siggg">Sign Up</span>
                            <svg
                              className="icon-arrow after"
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 403.8 200.3"

                              xmlSpace="preserve"
                            >

                              <g>
                                <g>
                                  <path
                                    d="M25.2,114.3h317.3L290,166.6c-5.9,5.8-5.9,15.3-0.1,21.2c5.8,5.9,15.3,5.9,21.2,0.1l78.3-77.9c0,0,0,0,0,0
           c5.9-5.8,5.9-15.4,0-21.2c0,0,0,0,0,0l-78.3-77.9c-5.9-5.8-15.4-5.8-21.2,0.1c-5.8,5.9-5.8,15.4,0.1,21.2l52.6,52.3H25.2
           c-8.3,0-15,6.7-15,15C10.2,107.6,16.9,114.3,25.2,114.3z"
                                    fill="#ff9800"
                                  />
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </button>

                      {!isLogin ? (
                        <button
                          className="btn_login_1"
                          onClick={handleLoginClick}
                        >
                          <div className="center">
                            <div className="radius">
                              <span>Login</span>
                              <svg
                                className="icon-arrow after"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 403.8 200.3"
                                style={{
                                  enableBackground: "new 0 0 403.8 200.3",
                                }}
                                xmlSpace="preserve"
                              >
                             
                                <g>
                                  <g>
                                    <path
                                      d="M25.2,114.3h317.3L290,166.6c-5.9,5.8-5.9,15.3-0.1,21.2c5.8,5.9,15.3,5.9,21.2,0.1l78.3-77.9c0,0,0,0,0,0
           c5.9-5.8,5.9-15.4,0-21.2c0,0,0,0,0,0l-78.3-77.9c-5.9-5.8-15.4-5.8-21.2,0.1c-5.8,5.9-5.8,15.4,0.1,21.2l52.6,52.3H25.2
           c-8.3,0-15,6.7-15,15C10.2,107.6,16.9,114.3,25.2,114.3z"
                                      fill="#ff9800"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </button>
                      ) : (
                        <button
                          className="btn_login_1"
                          onClick={() => navigate("/BranchPage")}
                        >
                          <div className="center">
                            <div className="radius">
                              <span>Skip</span>
                              <svg
                                className="icon-arrow after"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 403.8 200.3"
                                style={{
                                  enableBackground: "new 0 0 403.8 200.3",
                                }}
                                xmlSpace="preserve"
                              >
                               
                                <g>
                                  <g>
                                  <path
                                      d="M25.2,114.3h317.3L290,166.6c-5.9,5.8-5.9,15.3-0.1,21.2c5.8,5.9,15.3,5.9,21.2,0.1l78.3-77.9c0,0,0,0,0,0
           c5.9-5.8,5.9-15.4,0-21.2c0,0,0,0,0,0l-78.3-77.9c-5.9-5.8-15.4-5.8-21.2,0.1c-5.8,5.9-5.8,15.4,0.1,21.2l52.6,52.3H25.2
           c-8.3,0-15,6.7-15,15C10.2,107.6,16.9,114.3,25.2,114.3z"
                                      fill="#ff9800"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-lg-5 ">
              <div className="d-flex justify-content-center align-items-center right-out-dv flex-column">
                <div className="sl-r-bx">
                  <img className="r-img-bg" src={chkcrp} alt="img" />
                  <div className="sl-2-bg"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-relative">
          <div className="container">
            {/* <div className="download">
            <p className="dw-con">
              For Better Experience Download The Q2H App Now
            </p>
            <div className="images_pa">
              <a href="https://play.google.com/store/apps/details?id=com.falconsquare.q2hmeats">
                <img src={playstore} alt="img" className="ps1" />
              </a>
              <a href="https://apps.apple.com/in/app/q2h-quick-2-home-meat-delivery/id1662482699">
                <img src={appstore} alt="img" className="io1" />
              </a>
            </div>
          </div> */}
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-11">
                {" "}
                <div className="download-sect-animation">
                  <p className="dw-con">
                    <span className="better_1">For Better Experience </span>{" "}
                    <span className="better">Download The Q2H App Now</span>
                  </p>
                  <ul className=" dag">
                    <li className="download-temp-li">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.falconsquare.q2hmeats"
                        target="_blank"
                      >
                        <div class="download android">
                          <i class="fa fa fa-play store fa-3x"></i>
                          <div>
                            <span class="df">Download from</span>
                            <span class="dfn">Google Play</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className="download-temp-li">
                      <a href="https://apps.apple.com/in/app/q2h-quick-2-home-meat-delivery/id1662482699" target="_blank">
                        <div class="download apple">
                          <i class="fa fa fa-apple fa-3x"></i>
                          <span class="df">Download from</span>
                          <span class="dfn">App Store</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* <div className="col-lg-5"></div> */}
            </div>
          </div>
          <div className="or-bx02"></div>
        </div>
      </div>
      <div className="">
        <Footer />
      </div>
    </div>
  );
}

export default Login;
