import React, { useEffect, useState } from "react";
import "./Orderstatus.css";
function Orderstatus() {
  const [orders, setOrders] = useState([]);
  console.log("orders", orders[0]);

  useEffect(() => {
    handleOrders();
  }, []);

  const handleOrders = () => {
    fetch(
      "https://q2h.in/admin/api/order_details.php?order_id=2645"
    )
      .then((response) => response.json())
      .then((data) => setOrders(data.order_details))
      .catch((err) => console.log(err));
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-center">
        <h1>Order Status </h1>
      </div>
      <div className="m-3">
        {" "}
        {/* Reduced margin */}
        {orders.map((list, index) => (
          <div key={index} className="card p-3">
            {" "}
            {/* Reduced padding */}
            <h5 className="d-flex justify-content-center fw-bold line">
              ORDER DETAILS
            </h5>
            <div className="line-doted d-flex justify-content-between">
              {list.address === 0 ? (
                <>
                  <p className="text-start">Order type:</p>
                  <p className="text-end">Self Pickup</p>
                </>
              ) : (
                <>
                  <p className="text-start">Order type:</p>
                  <p className="text-end">Home Delivery</p>
                </>
              )}
            </div>
            <div className="container-fluid">
              {list.menu.map((item, idx) => (
                <div key={idx} >
                  <table className="table table-borderless">
                    <tbody className="table_align">
                      <tr >
                        <div className="row">
                          <div className="col-lg-6">
                            <td className="text-start">{item.ItemName}</td>
                          </div>
                          <div className="col-lg-3">
                            <td className="text-start">{item.ItemQty}</td>
                          </div>
                          <div className="col-lg-3">
                            <td className="text-start">₹{item.ItemTotalPrice}</td>
                          </div>
                        </div>
                      </tr>

                      {item.variant.map((variantItem, variantIdx) => (
                        <tr key={variantIdx}>
                          <td className="text-start">
                            {variantItem.variant_name}
                          </td>
                          <td className="text-start">{variantItem.heading}</td>
                          <td className="text-start">₹{variantItem.price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
            <div className="container">
              <h5 className="d-flex justify-content-center fw-bold line">
                PAYMENT DETAILS
              </h5>
              <div className="">
                <div className="p-3 line-doted">
                  {" "}
                  {/* Reduced padding */}
                  <div className="row">
                    <div className="col-8">
                      <p className="mb-0">Subtotal:</p>
                      <p className="mb-0">Delivery Charge:</p>
                      <p className="mb-0">Discount:</p>
                      <p className="mb-0">Tips:</p>
                    </div>
                    <div className="col-4">
                      <p className="mb-0">₹{list.subtot}</p>
                      <p className="mb-0">₹{list.dc}</p>
                      <p className="mb-0">₹{list.discount}</p>
                      <p className="mb-0">₹{list.tips}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-10 p-2">
                  <h5 className="mb-0">Total Price :</h5>
                </div>
                <div className="col-4 p-2">
                  <p className="mb-0">₹{list.total_price}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Orderstatus;
