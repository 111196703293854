import React, { useState, useEffect } from "react";
import "./BranchPage.css";
import q2h from "../../Assests/q2h.png";
import search from "../../Assests/search.png";
import offers from "../../Assests/offers.png";
import help from "../../Assests/help.png";
import sigin from "../../Assests/sigin.png";
import cart from "../../Assests/cart.png";
import { useNavigate } from "react-router-dom";
import loc from "../../Assests/placeholder.png";
import { FaMapMarkerAlt } from "react-icons/fa";

function BranchPage() {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [distances, setDistances] = useState([]);
  const [branches, setBranches] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [selectedBranchId, setSelectedBranchId] = useState(null);

  <script
    src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
    integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
    crossorigin="anonymous"
  ></script>;
  const navigate = useNavigate();

  const img_url = "https://q2h.in/admin/images/branch/";
  useEffect(() => {
    // Restore the selected branch ID from localStorage
    const savedBranchId = localStorage.getItem("branchid");
    if (savedBranchId) {
      setSelectedBranchId(savedBranchId);
    }
  }, []);
  const handleBranch = async (id) => {
    // console.log(id.id)

    localStorage.setItem("branchid", id.id);
    localStorage.setItem("dataFetched", "false");
    navigate("/Mainpage");
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          setCurrentLocation(userLocation);
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    const calculateDistance = (lat1, lon1, lat2, lon2) => {
      const R = 6371;
      const dLat = (lat2 - lat1) * (Math.PI / 180);
      const dLon = (lon2 - lon1) * (Math.PI / 180);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) *
          Math.cos(lat2 * (Math.PI / 180)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c;
      return distance;
    };

    const fetchBranches = async () => {
      try {
        if (currentLocation) {
          const response = await fetch(
            "https://q2h.in/admin/api/branchlist.php"
          );
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const data = await response.json();
          // di(aff(data.branch));
          setBranches(data.branch);

          const distancesFromUser = data.branch.map((branch) => {
            const [branchLat, branchLon] = branch.latlog
              .split(",")
              .map(parseFloat);
            const distance = calculateDistance(
              currentLocation.latitude,
              currentLocation.longitude,
              branchLat,
              branchLon
            );
            return { ...branch, distance };
          });

          setDistances(distancesFromUser);

          setFilteredBranches(data.branch); // Set filtered branches initially
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBranches();
  }, [currentLocation]);

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = branches.filter((branch) =>
      branch.branch_name.toLowerCase().includes(query)
    );

    setFilteredBranches(filtered);
  };
  const branchid = localStorage.getItem("branchid") || "";
  localStorage.setItem("prevBranchid", branchid);
  localStorage.removeItem("promoText");
  localStorage.removeItem("coupon_dis");
  
  return (
    <div className=" out-sect">
      <div className="home_headleft" onClick={() => window.location.reload()}>
        <img src={q2h} alt="img" className="img-cart" />
      </div>

      <div className="lin-or position-relative m-3">
        <span className="Select-shop"> Select Shop</span>
      </div>

      <div className="container m-auto p-3">
        <div className="lis-grid-container">
          {filteredBranches.map((branch, index) => (
            <div
              className="lis-grid-item"
              key={index}
              onClick={() => handleBranch({ id: branch.id })}
              style={{
                border:
                  selectedBranchId === branch.id ? "2px solid orange" : "none",
              }}
            >
              <div className="fd-img">
                <img
                  className=""
                  src={img_url + branch.image}
                  alt={branch.branch_name}
                />
              </div>
              <div className="branch-details">
                <p className="loc-top"> {branch.branch_name}</p>
                <div className="d-flex loc-top-sec align-items-center">
                  <FaMapMarkerAlt className="map-marker-icon" />
                  <div className="svg-container">
                    <svg width="100" height="70" viewBox="0 0 200 100">
                      <path
                        className="dotted-line"
                        d="M 10,50 L 180,50"
                        fill="none"
                        stroke="black"
                        strokeWidth="5"
                        stroke-dasharray="5,5"
                      />
                    </svg>
                  </div>

                  <FaMapMarkerAlt className="map-marker-icon m-1" />
                  <p className="font-km m-1">
                    {distances[index]?.distance !== undefined
                      ? distances[index].distance.toFixed(2)
                      : "Calculating..."}{" "}
                    km
                  </p>
                </div>
                <div>
                  {distances[index]?.distance < branch.km ? (
                    branch.homedelivery == 1 ? (
                      <div className="text-start1">
                        <span className="dis-fon">Door Delivery Available</span>
                        <span className="dis-fon">Self pickup</span>
                      </div>
                    ) : null
                  ) : branch.selfpickup == 1 ? (
                    <div className="text-start1" style={{ marginTop: "12px" }}>
                      <span className="dis-fon">Self pickup</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BranchPage;
