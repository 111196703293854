import React, { useState, useEffect } from "react";
import "./Feedback.css";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import { Modal } from "react-bootstrap";
const Feedback = () => {
  const [selectedRating, setSelectedRating] = useState("");
  const [selectedFeedback, setSelectFeedback] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const navigate = useNavigate();
  const handleRatingChange = (event) => {
    const selectedValue = event.target.id.split("-")[1];
    setSelectedRating(selectedValue);
    console.log("selectedValue", selectedValue);
  };

  const handlesubmit = async (event) => {
    event.preventDefault();

    const user_id = localStorage.getItem("user_id");
    const branch_id = localStorage.getItem("branch_id");

    const ratings = selectedRating;
    const selectedText = selectedFeedback;

    // Check if the feedback is empty
    if (!selectedText.trim() || !ratings.trim()) {
      alert("Please provide both feedback and ratings before submitting.");
      return;
    }

    const apiUrl = `https://q2h.in/admin/api/feedback.php?uid=${user_id}&rating=${ratings}&msg=${selectedText}&branch=${branch_id}`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        setShowSuccessModal(true);
        clearForm();
      } else {
        console.error("Failed to submit feedback. Status:", response.status);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const clearForm = () => {};
  const handleTextareaChange = (event) => {
    setSelectFeedback(event.target.value);
    console.log(selectedFeedback);
  };
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    window.location.reload();
  };
  useEffect(() => {
    console.log(selectedFeedback);
  }, [selectedFeedback]);
  return (
    <div>
      <button onClick={() => navigate("/orders")} className="btn-back">
        <FaArrowLeft />
      </button>
      <div className="con-main">
        <div className="feedback  mt-3">
          <div className="rating">
            <input
              type="radio"
              name="rating"
              id="rating-5"
              onChange={handleRatingChange}
            />
            <label htmlFor="rating-5"></label>
            <input
              type="radio"
              name="rating"
              id="rating-4"
              onChange={handleRatingChange}
            />
            <label htmlFor="rating-4"></label>
            <input
              type="radio"
              name="rating"
              id="rating-3"
              onChange={handleRatingChange}
            />
            <label htmlFor="rating-3"></label>
            <input
              type="radio"
              name="rating"
              id="rating-2"
              onChange={handleRatingChange}
            />
            <label htmlFor="rating-2"></label>
            <input
              type="radio"
              name="rating"
              id="rating-1"
              onChange={handleRatingChange}
            />
            <label htmlFor="rating-1"></label>
            <div className="emoji-wrapper">
              <div className="emoji p-4">
                <span className="Feedback">
                  {selectedRating === "" ? "Give Feedback..!" : ""}
                </span>

                {selectedRating !== "" && (
                  <>
                    {selectedRating === "1" && (
                      <>
                        😔 <span className="span-txt">Bad</span>
                      </>
                    )}
                    {selectedRating === "2" && (
                      <>
                        😐 <span className="span-txt">Poor</span>
                      </>
                    )}
                    {selectedRating === "3" && (
                      <>
                        😊 <span className="span-txt">Average</span>
                      </>
                    )}
                    {selectedRating === "4" && (
                      <>
                        😃 <span className="span-txt">Good</span>
                      </>
                    )}
                    {selectedRating === "5" && (
                      <>
                        🤩 <span className="span-txt">Excellent</span>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="con-main w-100 mt-2">
          <div className=" p-4">
            <p className="txt-feed ">
              What are the main reasons for your rating?
            </p>

            <textarea
              name="textarea"
              value={selectedFeedback}
              onChange={(e) => handleTextareaChange(e)}
              type="text"
              className="form-control"
              placeholder="Enter your reasons"
            />
            <div>
              <div className="d-flex justify-content-center m-2">
                <button onClick={handlesubmit} className="btn-sub m-1 p-1">
                  Submit
                </button>
                {/* <button className="btn-cancel p-1">Cancel</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-svg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#ff5500"
            fillOpacity="1"
            d="M0,128L80,133.3C160,139,320,149,480,176C640,203,800,245,960,234.7C1120,224,1280,160,1360,128L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </div>
      <Modal
        className="feed-sub"
        show={showSuccessModal}
        onHide={handleCloseSuccessModal}
      >
       <Modal.Body className="feed-body">
  <h4 className="text-center">Feedback Submitted Successfully</h4>
  <h6 className="text-center">Thank you for your feedback!</h6>
  <div className="text-center">
    <button className="feed-but m-3" onClick={handleCloseSuccessModal}>
      OK
    </button>
  </div>
</Modal.Body>


        
      </Modal>
    </div>
  );
};

export default Feedback;
