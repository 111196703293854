import PostData from "./PostData";
const PRIMARY_URL = "https://q2h.in/admin/api/";  //
  // const PRIMARY_URL ="https://falconsquare.in/RN_test/q2h-test/admin/api/"
export default class Api {
  getBranch() {
    return PostData(PRIMARY_URL + "branchlist.php");
  }
  getSignUp({
    maild = "",
    mobile_no = "",
    token = "",
    hash = "",
    type = "",
    apiPath = "web_signup.php",
  }) {
    return PostData(
      PRIMARY_URL +
        apiPath +
        `?maild=${maild}&mobile_no=${mobile_no}&token=${token}&hash=${hash}&type=${type}`
    );
  }
  getLogin({
    mobile_no = "",
    token = "",
    hash = "",
    type = "",
    apiPath = "web_login.php",
  }) {
    return PostData(
      PRIMARY_URL +
        apiPath +
        `?mobile_no=${mobile_no}&token=${token}&hash=${hash}&type=${type}`
    );
  }

  getOtp({ mobile_no = "", otp = "", apiPath = "verifyotp.php" }) {
    return PostData(
      PRIMARY_URL + apiPath + `?mobile_no=${mobile_no}&token=${otp}`
    );
  }
  getRazorPayToken({
    billTotal = "",
    apiPath = "razor_pay_order_id_generate.php",
  }) {
    return PostData(PRIMARY_URL + apiPath + `?amount_get=${billTotal}`);
  }

  getCashFreeToken({
    billTotal = "",
    orderID = "",
    apiPath = "cashfree_generate_token_id.php",
  }) {
    return PostData(
      PRIMARY_URL + apiPath + `?amt=${billTotal}&orderId=${orderID}`
    );
  }

  updateOrderId({
    orderID = "",
    tokenId = "",
    paymentMethod = "",
    apiPath = "razorpay_order_update.php",
  }) {
    return PostData(
      PRIMARY_URL +
        apiPath +
        `?oid=${orderID}&order_id=${tokenId}&method=${paymentMethod}`
    );
  }

  placeOrder({
    userId = "",
    name = "user",
    mailId = "",
    addressFoorOrder = "",
    paymentType = "",
    instructions = "",
    city = "cbe",
    food_desc = "",
    total_price = 0,
    latlongFoodOrder = "",
    fireBaseToken = "",
    payId = "",
    a_idFoorOrder = "",
    deliveryCharges = "",
    billDiscount = "",
    selftime = "",
    tipAmount = "",
    branchId = "",
    future = "",
    app_Version = "",
    apiPath = "food_order.php",
  }) {
    return PostData(
      PRIMARY_URL +
        apiPath +
        `? user_id=${userId}&
      name=${name}&
      email=${mailId}&
      address=${addressFoorOrder}&
      payment_type=${paymentType}&
      notes=${instructions}&
      city=${city}&
      food_desc=${food_desc}&
      total_price=${total_price}&
      latlong=${latlongFoodOrder}&
      token_id=${fireBaseToken}&
      pay_id=${payId}&
      a_id=${a_idFoorOrder}&
      rc=${0}&
      dc=${deliveryCharges}&
      pc=${0}&
      discount=${billDiscount}&
      selftime=${selftime}&
      tips=${tipAmount}&
      bid=${branchId}&
      future=${future} &
      app_version=${app_Version}`
    );
  }

  getAddress({ uid = "", apiPath = "get_address.php" }) {
    return PostData(PRIMARY_URL + apiPath + `?uid=${uid}`);
  }

  deleteAddress({ del_id = "", bid = "", apiPath = "add_address.php" }) {
    return PostData(PRIMARY_URL + apiPath + `?del_id=${del_id}&bid=${bid}`);
  }

  branchList({ apiPath = "branchlist.php" }) {
    return PostData(PRIMARY_URL + apiPath);
  }

  updateOrderId({
    orderID = "",
    tokenId = "",
    paymentMethod = "",
    apiPath = "razorpay_order_update.php",
  }) {
    return PostData(
      PRIMARY_URL +
        apiPath +
        `?oid=${orderID}&order_id=${tokenId}&method=${paymentMethod}`
    );
  }

  razorPayWebCheckout({
    oid = "",
    raz_payid = "",
    raz_sign = "",
    raz_oid = "",
    apiPath = "razorpay_web_checkout.php",
  }) {
    return PostData(
      PRIMARY_URL +
        apiPath +
        `?oid=${oid}&raz_payid=${raz_payid}&raz_oid=${raz_oid}&raz_sign=${raz_sign}`
    );
  }

  updatePaymentId({
    oid = "",
    referenceId = "",
    status = "",
    apiPath = "paystatus.php",
  }) {
    return PostData(
      PRIMARY_URL +
        apiPath +
        `?oid=${oid}&pay_id=${referenceId}&pay_status=${status}`
    );
  }

  addAddress({
    uid = "",
    type = "",
    name = "",
    latlng = "",
    line1 = "",
    landmark = "",
    addiphne = "",
    bid = "",
    apiPath = "add_address.php",
  }) {
    return PostData(
      PRIMARY_URL +
        apiPath +
        `?uid=${uid}&type=${type}&name=${name}&latlng=${latlng}&line1=${line1}&landmark=${landmark}&addiphne=${addiphne}&bid=${bid}`
    );
  }

  editAddress({
    aid="",
    uid = "",
    type = "",
    name = "",
    latlng = "",
    line1 = "",
    landmark = "",
    addiphne = "",
    bid = "",
    apiPath = "add_address.php",
  }) {
    return PostData(
      PRIMARY_URL +
        apiPath +
        `?aid=${aid}&uid=${uid}&type=${type}&name=${name}&latlng=${latlng}&line1=${line1}&landmark=${landmark}&addiphne=${addiphne}&bid=${bid}`
    );
  }
}
